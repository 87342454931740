import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import getData from "../getdata/GetData";
import FunctionChange from "components/general/FunctionChange";
import { arrConstBranch } from "../const/ConstTicket";
import { FiTrash } from "react-icons/fi";

function Branch() {
  const { id } = useParams();
  const [arrBranch, setArrBranch] = useState([]);

  useEffect(() => {
    getBranch();
  }, []);

  const getBranch = async (id_item, action, edit_field, value) => {
    const request_parameters = {
      module: "settings",
      block: "counterpartie_branch_contact",
    };
    const request_attribute = {
      type_item: "branch",
      id_counterpartie: id,
      id_item: id_item,
      action: action,
    };

    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrListBranch =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setArrBranch(arrListBranch);
  };

  function getValue(fieldType, value, id_item) {
    getBranch(id_item, "", fieldType, value);
  }

  return (
    <>
      <div className="counterpartie_branch">
        <div className="border_background col-12 p-4">
          <div className="border_bg_b_c_three a-flex pb-2">
            <div className="a-text-2 pb-2">Филиалы</div>
          </div>

          <div className="">
            {arrBranch
              ? arrBranch.map((branchvalue, index) =>
                  branchvalue ? (
                    <div
                      className="pt-4 pb-4 border_bg_b_c_three a-relative"
                      key={index}
                    >
                      <div className="a-text-2 pb-2">
                        {branchvalue.name.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={branchvalue.name.value}
                            fieldType={branchvalue.name.field}
                            propsState={branchvalue.new}
                            propsIditem={branchvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>
                      <div className="pb-2 counterpartie_branch_item_grid">
                        <div className="">{arrConstBranch.adress.title}</div>
                        <div className="">
                          {branchvalue.adress.field ? (
                            <FunctionChange
                              getValue={getValue}
                              propsValue={branchvalue.adress.value}
                              fieldType={branchvalue.adress.field}
                              propsIditem={branchvalue.id.value}
                            />
                          ) : (
                            false
                          )}
                        </div>
                      </div>

                      <div className="pb-2 counterpartie_branch_item_grid">
                        <div className="">
                          {arrConstBranch.branch_type.title}
                        </div>
                        {branchvalue.branch_type.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={branchvalue.branch_type.value}
                            fieldType={branchvalue.branch_type.field}
                            propsInput="select"
                            propsParamData={{
                              module: "settings",
                              block: "counterpartie_branch_contact",
                              for: "branchType",
                            }}
                            propsIditem={branchvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>

                      <div className="pb-2 counterpartie_branch_item_grid">
                        <div className="">{arrConstBranch.phone.title}</div>
                        {branchvalue.phone.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={branchvalue.phone.value}
                            fieldType={branchvalue.phone.field}
                            propsIditem={branchvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>

                      <div className="pb-2 counterpartie_branch_item_grid">
                        <div className="">{arrConstBranch.mail.title}</div>
                        {branchvalue.mail.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={branchvalue.mail.value}
                            fieldType={branchvalue.mail.field}
                            propsIditem={branchvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>

                      <div className="pb-2 counterpartie_branch_item_grid">
                        <div className="">{arrConstBranch.time.title}</div>
                        {branchvalue.time.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={branchvalue.time.value}
                            fieldType={branchvalue.time.field}
                            propsIditem={branchvalue.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>

                      <div
                        className="fitrash-bottom-right"
                        onClick={() => {
                          if (window.confirm("Удалить филиал?")) {
                            getBranch(branchvalue.id.value, "delete");
                          }
                        }}
                      >
                        <FiTrash />
                      </div>
                    </div>
                  ) : (
                    false
                  )
                )
              : false}
          </div>
          <div className="pt-4">
            <div
              className="a-btn-block-bottom-left"
              onClick={() => getBranch("", "add")}
            >
              Добавить филиал
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Branch;
