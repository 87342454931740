export const arrSelectors = {
  status: {
    title: "Статус",
    default: "Отсутствует",
    module: "settings",
    block: "statuses",
    type: "selector",
  },
  priority: {
    title: "Приоритет",
    default: "Отсутствует",
    module: "settings",
    block: "priorities",
    type: "selector",
  },
  project: {
    title: "Клиент",
    default: "Отсутствует",
    module: "settings",
    block: "projects",
    type: "selector",
  },
  users_executor: {
    title: "Исполнитель",
    default: "Отсутствует",
    module: "settings",
    block: "users",
    type: "selector",
  },
  date_create: {
    title: "Дата создания",
    default: "Отсутствует",
    module: "settings",
    block: "users",
    type: "date",
  },
  date_control: {
    title: "Дата контроля",
    default: "Отсутствует",
    module: "settings",
    block: "users",
    type: "date",
  },

  date_close: {
    title: "Дата закрытия",
    default: "Отсутствует",
    module: "settings",
    block: "users",
    type: "date",
  },
  users_co_executor: {
    title: "Соисполнитель",
    default: "Отсутствует",
    module: "settings",
    block: "users",
    type: "selector",
  },
  users_initiator: {
    title: "Инициатор",
    default: "Отсутствует",
    module: "settings",
    block: "users",
    type: "selector",
  },
  users_observer: {
    title: "Наблюдатель",
    default: "Отсутствует",
    module: "settings",
    block: "users",
    type: "selector",
  },
  department: {
    title: "Отдел",
    default: "Отсутствует",
    module: "settings",
    block: "departments",
    type: "selector",
  },
  type: {
    title: "Тип задачи",
    default: "Отсутствует",
    module: "settings",
    block: "tickets_type",
    type: "selector",
  },
  runtime: {
    title: "Время на выполнение",
    default: "Отсутствует",
    module: "settings",
    block: "runtime",
    type: "selector",
  },
  common: {
    title: "Видимость шаблона",
    default: "Персональный",
    module: "settings",
    block: "template_ticket_common",
    type: "selector",
  },
};
