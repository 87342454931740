function NotFound() {
  return (
    <>
      <div className="p-4">
        <div className="a-text-1 pb-4">Страница не найдена</div>
        <div className="a-text-3 pb-2">
          К сожалению, запрашиваемая страница не найдена.
        </div>
        <div>
          Возможно, вы ошиблись в адресе или страница была удалена. Пожалуйста,
          проверьте URL и повторите попытку.
        </div>
        <div>
          Если вы считаете, что это ошибка, пожалуйста, свяжитесь с
          администратором системы Aritin для уточнения информации.
        </div>
      </div>
    </>
  );
}

export default NotFound;
