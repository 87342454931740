import { useDrop } from "react-dnd";
import { FiTrash } from "react-icons/fi";
import FunctionChange from "components/general/FunctionChange";
import ArticleTitleCat from "./ArticleTitleCat";

function KnowledgeCat({
  knowcategoriesmap,
  index,
  getValue,
  getKnowledge,
  knowArticles,
}) {
  const [canDrop, dropRef] = useDrop({
    accept: "knowledge",
    drop(item) {
      getKnowledge(
        "edit_art",
        item.id,
        "categorie",
        knowcategoriesmap.id.value
      );
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <>
      <div
        key={index}
        ref={dropRef}
        className={`knowledge-categories-article ${
          canDrop.canDrop === true ? "drop-article" : ""
        }`}
      >
        {knowcategoriesmap &&
        knowcategoriesmap.name &&
        knowcategoriesmap.name.value ? (
          <div className="knowledge-categories">
            <div className="knowledge-categories-title">
              <FunctionChange
                getValue={getValue}
                propsValue={knowcategoriesmap.name.value}
                propsIditem={knowcategoriesmap.id.value}
              />
            </div>
            <div
              className="knowledge-categories-fitrash"
              onClick={() => {
                if (window.confirm("Удалить?")) {
                  getKnowledge("del_cat", knowcategoriesmap.id.value);
                }
              }}
            >
              <FiTrash />
            </div>
          </div>
        ) : (
          false
        )}
        {knowArticles
          ? knowArticles
              .filter(
                (knowarticlesfilter) =>
                  knowarticlesfilter.categorie.value ===
                  knowcategoriesmap.id.value
              )
              .map((articlerticlesmap, index2) => (
                <ArticleTitleCat
                  key={index2}
                  articlerticlesmap={articlerticlesmap}
                  index2={index2}
                />
              ))
          : false}
      </div>
    </>
  );
}

export default KnowledgeCat;
