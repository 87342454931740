import React, { useState, useEffect } from "react";
import getData from "../getdata/GetData";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../../../css/template.css";

function TemplateTickets() {
  let { id } = useParams();
  const [template, setTemplate] = useState([]);
  const [templateBurger, setTemplateBurger] = useState([]);

  useEffect(() => {
    getTemplateTickets();
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".burgerButton")) {
      setTemplateBurger([]);
    }
  };

  const getTemplateTickets = async (id_template, action) => {
    const request_parameters = {
      module: "sd",
      block: "templates_list",
    };
    const request_attribute = {
      id_template,
      action,
    };
    const result = await getData(request_parameters, request_attribute);

    const idTicket =
      result && result.request_data && result.request_data.new_ticket
        ? result.request_data.new_ticket
        : null;

    if (idTicket && idTicket > 0) {
      window.location.replace("/task/" + idTicket + "/");
    }

    const idtemplate =
      result && result.request_data && result.request_data.new_template
        ? result.request_data.new_template
        : null;

    if (idtemplate && idtemplate > 0) {
      window.location.replace("/template_task/" + idtemplate + "/");
    }

    const resultTemplateTickets =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setTemplate(resultTemplateTickets);
  };

  return (
    <>
      <div className="template_container">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 col-7 d-flex">
              <div className="a-text-1 a-section-title">Шаблоны</div>
            </div>
            <div className="col-sm-2 col-5 d-flex">
              <div className=" ">
                <div
                  className="a-btn-block"
                  onClick={() => getTemplateTickets("", "new_template")}
                >
                  Добавить шаблон
                </div>
              </div>
            </div>
          </div>

          {template.length > 0 ? (
            <div className="a-border-bg">
              {template
                ? template.map((templatevalue, index) => (
                    <div key={index} className="template-grid-template">
                      <div
                        className={`burger ${
                          templateBurger === templatevalue.id.value
                            ? "active"
                            : ""
                        }`}
                      >
                        <div
                          className="burgerButton"
                          onClick={() =>
                            setTemplateBurger(templatevalue.id.value)
                          }
                        >
                          <div className="middle"></div>
                        </div>
                        <div className="burgermenu border_background">
                          {templatevalue.menu.map((menu, index) => (
                            <div
                              key={index}
                              className="burger-menu-item a-pointer"
                              onClick={() => {
                                if (window.confirm(menu.value)) {
                                  menu.action === "change_template"
                                    ? window.location.replace(
                                        "/template_task/" +
                                          templatevalue.id.value +
                                          "/"
                                      )
                                    : getTemplateTickets(
                                        templatevalue.id.value,
                                        menu.action
                                      );
                                }
                              }}
                            >
                              {menu.value}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <Link to={`/template_task/${templatevalue.id.value}/`}>
                          {templatevalue.name.value}
                        </Link>
                      </div>
                    </div>
                  ))
                : false}
            </div>
          ) : (
            <div className="pt-4">
              <div className="a-text-3 pb-2">
                Ваш список шаблонов в настоящее время пуст.
              </div>
              <div>
                Для создания нового шаблона, нажмите на кнопку «Добавить
                шаблон», расположенную в правом верхнем углу экрана.
              </div>
              <div>
                Это позволит вам эффективно организовать и управлять вашими
                проектами.
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default TemplateTickets;
