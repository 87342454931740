import { Routes, Route } from "react-router-dom";
import TableTickets from "components/unit/servicdesk/tickets/TableTickets";
import Ticket from "components/unit/servicdesk/tickets/Ticket";
import Counterparties from "components/unit/servicdesk/clients/Сounterparties";
import Structure from "components/unit/servicdesk/settings/Structure";
import Staff from "components/unit/servicdesk/settings/Staff";
import User from "components/unit/servicdesk/settings/User";
import Roles from "components/unit/servicdesk/settings/Roles";
import Сounterpartie from "components/unit/servicdesk/clients/Сounterpartie";
import Payment from "components/unit/servicdesk/settings/Payment";
import Contactsupport from "components/unit/servicdesk/settings/Contactsupport";
import Settings from "components/unit/settings/Settings";
import TemplateTicket from "components/unit/servicdesk/settings/TemplateTicket";
import TemplateTickets from "components/unit/servicdesk/settings/TemplateTickets";
import TicketsFast from "components/unit/servicdesk/ticketsfast/TicketsFast";
import TableTicketsFast from "components/unit/servicdesk/ticketsfast/TableTicketsFast";
import Knowledge from "components/unit/knowledge/Knowledge";
import PlugAccess from "./PlugAccess";
import NotFound from "./NotFound";
import NotPaid from "./NotPaid";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

function Routing({ client, module, paid }) {
  const location = useLocation();

  useEffect(() => {
    // Запускаем Jivo на странице contact_support
    if (window.location.href.includes("contact_support")) {
      if ($("body > jdiv").length) {
        $("body > jdiv").css("display", "inline");
      } else {
        const script = document.createElement("script");
        script.src = "//code.jivo.ru/widget/CaqZJH9Qmu";
        script.async = true;
        document.body.appendChild(script);
      }

      return () => {
        if ($("body > jdiv").length) {
          $("body > jdiv").css("display", "none");
        }
      };
    }
  }, [location]);

  return (
    <div className="content-routing">
      {paid !== "N" ? (
        <Routes>
          <Route
            path="/task/"
            element={
              client && client === "inside" ? <TableTickets /> : <PlugAccess />
            }
          />
          <Route
            path="/knowledge/"
            element={
              client && client === "inside" ? <Knowledge /> : <PlugAccess />
            }
          />
          <Route
            path="/knowledge/:id/"
            element={
              client && client === "inside" ? <Knowledge /> : <PlugAccess />
            }
          />
          <Route
            path="/task/:id/"
            element={
              client && client === "inside" ? <Ticket /> : <PlugAccess />
            }
          />
          <Route
            path="/counterparties/"
            element={
              client && client === "inside" ? (
                <Counterparties />
              ) : (
                <PlugAccess />
              )
            }
          />
          <Route
            path="/counterparties/:id/"
            element={
              client && client === "inside" ? <Сounterpartie /> : <PlugAccess />
            }
          />
          <Route
            path="/structure/"
            element={
              client && client === "inside" ? <Structure /> : <PlugAccess />
            }
          />
          <Route
            path="/staff/"
            element={client && client === "inside" ? <Staff /> : <PlugAccess />}
          />
          <Route
            path="/staff/:id/"
            element={
              client && client === "inside" ? (
                <User propsModule={module} />
              ) : (
                <PlugAccess />
              )
            }
          />
          <Route
            path="/roles/"
            element={client && client === "inside" ? <Roles /> : <PlugAccess />}
          />
          <Route
            path="/payment/"
            element={
              client && client === "inside" ? <Payment /> : <PlugAccess />
            }
          />
          <Route
            path="/settings/"
            element={
              client && client === "inside" ? <Settings /> : <PlugAccess />
            }
          />
          <Route path="/contact_support/" element={<Contactsupport />} />
          <Route
            path="/"
            element={
              client && client === "inside" ? (
                <TableTickets />
              ) : (
                <TableTicketsFast />
              )
            }
          />
          <Route path="/template_task/" element={<TemplateTickets />} />
          <Route
            path="/template_task/:id/"
            element={
              client && client === "inside" ? (
                <TemplateTicket />
              ) : (
                <PlugAccess />
              )
            }
          />
          <Route path="/ticket/" element={<TableTicketsFast />} />
          <Route path="/ticket/:id/" element={<TicketsFast />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/payment/"
            element={
              client && client === "inside" ? <Payment /> : <PlugAccess />
            }
          />

          <Route path="/contact_support/" element={<Contactsupport />} />

          <Route path="*" element={<NotPaid />} />
        </Routes>
      )}
    </div>
  );
}

export default Routing;
