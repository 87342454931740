import React, { useState, useEffect } from "react";
import { CiEdit } from "react-icons/ci";
import getData from "../getdata/GetData";
import {
  IoClose,
  IoDocumentOutline,
  IoDuplicateOutline,
  IoFolderOpenOutline,
  IoCheckmarkOutline,
} from "react-icons/io5";
import $ from "jquery";
import Spinner from "react-bootstrap/Spinner";
import GallerySlider from "components/general/GallerySlider";
import AritinEditor from "components/general/AritinEditor";

function TicketMain({
  idTicket,
  changeField,
  selectedFile,
  setChangeField,
  setSelectedFile,
  typeItem,
  setTicketStatusCode,
}) {
  const [ticketMain, setTicketMain] = useState([]);
  const [download, setDownload] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImageIndex, setGalleryImageIndex] = useState("");
  const [changeFieldMain, setChangeFieldMain] = useState(false);
  const [accessMain, setAccessMain] = useState("read");

  function addOnForm(changeFile) {
    let id_item = changeFile.target.getAttribute("data-folder");
    if (changeFile && changeFile.target && changeFile.target.files[0]) {
      let arrFilesAll = Array.from(changeFile.target.files);
      getTicketMain("", "add_files", "", id_item, arrFilesAll);
    }
  }

  useEffect(() => {
    if (idTicket > 0) {
      getTicketMain();
    }
  }, [idTicket]);

  async function getTicketMain(save, type, value, id_item, arrFilesAll) {
    const request_parameters = {
      module: "sd",
      block: "ticket_main",
    };
    const request_attribute = {
      id_ticket: idTicket,
      id_item: id_item,
      typeItem,
    };

    let request_data = [];

    if (
      save === "save" ||
      type === "folder_name" ||
      type === "delete_folder" ||
      type === "delete_files" ||
      type === "add_folder"
    ) {
      request_data = {
        edit_field: type,
        value: value,
      };
    }

    let result = [];

    if (type === "add_files") {
      setDownload((prevItems) => [...prevItems, parseFloat(id_item)]);

      request_data = {
        edit_field: type,
        value: value,
      };

      result = await getData(
        request_parameters,
        request_attribute,
        request_data,
        arrFilesAll
      );
    } else {
      result = await getData(
        request_parameters,
        request_attribute,
        request_data,
        selectedFile
      );
    }

    setDownload((prevItems) => {
      let newItems = prevItems.slice();
      let id_item_float = parseFloat(id_item);
      let index_item = newItems.indexOf(id_item_float);
      if (index_item >= 0) {
        newItems.splice(index_item, 1);
      }
      return newItems;
    });

    const arrTicketMain =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    const stringAccessMain =
      result && result.request_data && result.request_data.access
        ? result.request_data.access
        : "read";

    if (
      result &&
      result.request_attribute &&
      result.request_attribute.status_code
    ) {
      setTicketStatusCode(result.request_attribute.status_code);
    }

    setTicketMain(arrTicketMain);
    setAccessMain(stringAccessMain);
    setChangeField(false);
    setSelectedFile([]);
  }

  function saveDescription(textValue) {
    getTicketMain("save", "description", textValue);
  }

  return (
    <>
      {setTicketStatusCode ? (
        <div>
          <div className="template_name_block">
            {typeItem && typeItem === "template" ? (
              <div className="ticket_box_name template_box_name">
                {changeField === "template_name" ? (
                  <div className="input_ticket_box_text textarea_subject">
                    <textarea id="template_name" rows="1">
                      {ticketMain.template_name &&
                      ticketMain.template_name.value
                        ? ticketMain.template_name.value
                        : null}
                    </textarea>

                    <div className="save_textarea__cancel_textarea d_flex_g20 h_maxc fs_13">
                      <div
                        className="save-textarea cp "
                        onClick={() => {
                          var textareaValue = $("#template_name").val();
                          getTicketMain("save", "template_name", textareaValue);
                        }}
                      >
                        Сохранить
                      </div>
                      <div
                        className="cancel-textarea cp "
                        onClick={() => {
                          setChangeField(false);
                          setSelectedFile([]);
                        }}
                      >
                        Отменить
                      </div>
                    </div>
                  </div>
                ) : ticketMain.template_name &&
                  ticketMain.template_name.value ? (
                  <div
                    className="ticket_name_value template_name_value"
                    onClick={() => setChangeField("template_name")}
                  >
                    {ticketMain.template_name.value}

                    <div className="icon-ticket-title cp">
                      <CiEdit />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              false
            )}
          </div>
          <div className="ticket_box_name__ticket_box_number b_b_bg">
            <div className="ticket_box_name">
              {changeField === "subject" ? (
                <div className="input_ticket_box_text textarea_subject">
                  <textarea id="textarea-subject" rows="1">
                    {ticketMain.subject && ticketMain.subject.value
                      ? ticketMain.subject.value
                      : null}
                  </textarea>

                  <div className="save_textarea__cancel_textarea d_flex_g20 h_maxc fs_13">
                    <div
                      className="save-textarea cp "
                      onClick={() => {
                        var textareaValue = $("#textarea-subject").val();
                        getTicketMain("save", "subject", textareaValue);
                      }}
                    >
                      Сохранить
                    </div>
                    <div
                      className="cancel-textarea cp "
                      onClick={() => {
                        setChangeField(false);
                        setSelectedFile([]);
                      }}
                    >
                      Отменить
                    </div>
                  </div>
                </div>
              ) : ticketMain.subject && ticketMain.subject.value ? (
                <div
                  className="ticket_name_value mobile_ticket_name_value"
                  onClick={() =>
                    ticketMain.subject.access === "change"
                      ? setChangeField("subject")
                      : false
                  }
                >
                  {ticketMain.subject.value}

                  {ticketMain.subject.access === "change" ? (
                    <div className="icon-ticket-title cp">
                      <CiEdit />
                    </div>
                  ) : (
                    false
                  )}
                </div>
              ) : null}
            </div>
            <div className="ticket_box_number">
              {ticketMain && ticketMain.id && typeItem !== "template" ? (
                <div className="ticket-number">
                  <div className="ticket-number-title">Задача №</div>
                  <div className="ticket-number-value">
                    {ticketMain.id.value}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="ticket-box-text aritin-editor-box">
            {ticketMain &&
            ticketMain.description &&
            ticketMain.description.type ? (
              <AritinEditor
                textProps={
                  ticketMain.description.value
                    ? ticketMain.description.value
                    : ""
                }
                saveTextProps={saveDescription}
                propsAccess={ticketMain.description.access}
                setChangeField={setChangeFieldMain}
                changeField={changeFieldMain}
              />
            ) : (
              false
            )}
          </div>

          <div>
            <GallerySlider
              galleryImages={galleryImages}
              galleryImageIndex={galleryImageIndex}
              setGalleryImageIndex={setGalleryImageIndex}
            />
          </div>

          <div className="ticket_main_files-groups">
            {ticketMain && ticketMain.files
              ? ticketMain.files.map((groupFiles, index) => (
                  <div key={index} className="ticket_main_files-group">
                    <div className="ticket_main_files-group_name">
                      <div className="ticket_main_files-group_name_icon">
                        <IoFolderOpenOutline />
                      </div>

                      {changeField === "foldername" + index ? (
                        <div className="ticket_main_files-group_name_edit">
                          <textarea id="folder_id_group" rows="1">
                            {groupFiles && groupFiles.name
                              ? groupFiles.name
                              : "Нет названия"}
                          </textarea>

                          <div
                            className="save cp "
                            onClick={() => {
                              var textareaValue = $("#folder_id_group").val();
                              getTicketMain(
                                "",
                                "folder_name",
                                textareaValue,
                                index
                              );
                            }}
                          >
                            <IoCheckmarkOutline />
                          </div>
                          <div
                            className="cansel cp "
                            onClick={() => {
                              setChangeField(null);
                            }}
                          >
                            <IoClose />
                          </div>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            accessMain && accessMain === "change"
                              ? setChangeField("foldername" + index)
                              : false
                          }
                        >
                          {groupFiles.name}

                          {accessMain && accessMain === "change" ? (
                            <div className="ticket_main_files-group_edit_icon">
                              <CiEdit />
                            </div>
                          ) : (
                            false
                          )}
                        </span>
                      )}
                    </div>

                    {groupFiles &&
                    groupFiles.files &&
                    groupFiles.files.image ? (
                      <div className="ticket_main_files-image_items">
                        {groupFiles.files.image.map((fileImage, indexImage) => (
                          <div
                            key={indexImage}
                            className="ticket_main_files-image_item"
                          >
                            <a>
                              <img
                                src={fileImage.url}
                                onClick={() => {
                                  setGalleryImages(groupFiles.files.image);
                                  setGalleryImageIndex(indexImage);
                                }}
                              />
                            </a>

                            {accessMain && accessMain === "change" ? (
                              <div
                                className="ticket_main_files-image_del"
                                onClick={() => {
                                  if (window.confirm("Удалить файл?")) {
                                    getTicketMain(
                                      "",
                                      "delete_files",
                                      fileImage.id
                                    );
                                  }
                                }}
                              >
                                <IoClose />
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      false
                    )}

                    {(groupFiles &&
                      groupFiles.files &&
                      groupFiles.files.other) ||
                    (download && download.indexOf(index) >= 0) ? (
                      <div className="ticket_main_files-other_items">
                        {groupFiles &&
                        groupFiles.files &&
                        groupFiles.files.other
                          ? groupFiles.files.other.map(
                              (fileOther, indexOther) => (
                                <div
                                  key={indexOther}
                                  className="ticket_main_files-other_item"
                                >
                                  <div className="ticket_main_files-other_name">
                                    <a href={fileOther.url} target="_blank">
                                      {fileOther.name}
                                    </a>
                                    <div className="ticket_main_files-other_icon">
                                      <a href={fileOther.url} target="_blank">
                                        <IoDocumentOutline />
                                      </a>
                                    </div>
                                    <div className="ticket_main_files-other_size">
                                      {fileOther.size}
                                    </div>
                                    <div
                                      className="ticket_main_files-other_del"
                                      onClick={() => {
                                        if (window.confirm("Удалить?")) {
                                          getTicketMain(
                                            "",
                                            "delete_files",
                                            fileOther.id
                                          );
                                        }
                                      }}
                                    >
                                      <IoClose />
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          : false}

                        <div className="ticket_main_files-other_item">
                          <div className="ticket_main_files-other_name">
                            {download && download.indexOf(index) >= 0 ? (
                              <div className="ticket_main_files-other_download">
                                <div className="">Загрузка файла</div>
                                <Spinner animation="border" />
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      false
                    )}

                    {typeItem && typeItem === "template" ? (
                      <div className="ticket_main_files-add_file-info p-2">
                        Добавление файла возможно в задаче
                      </div>
                    ) : (
                      <div className="ticket_main_files-add_file">
                        <form method="post" encType="multipart/form-data">
                          {accessMain && accessMain === "change" ? (
                            <label htmlFor={"input_id_" + index}>
                              <IoDuplicateOutline className="ticket_main_files-add_file_icon" />
                              Добавить файл
                            </label>
                          ) : (
                            false
                          )}

                          <input
                            className="class_display_none"
                            id={"input_id_" + index}
                            type="file"
                            multiple="multiple"
                            data-folder={index}
                            onChange={addOnForm}
                          />
                        </form>
                      </div>
                    )}

                    {ticketMain.files.length > 1 ? (
                      accessMain && accessMain === "change" ? (
                        <div
                          className="ticket_main_files-group_del"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Удалить папку «" +
                                  groupFiles.name +
                                  "» ? Удалятся все файлы в папке"
                              )
                            ) {
                              getTicketMain("", "delete_folder", "", index);
                            }
                          }}
                        >
                          Удалить папку
                        </div>
                      ) : (
                        false
                      )
                    ) : accessMain && accessMain === "change" ? (
                      <div
                        className="ticket_main_files-group_del"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Очистить папку «" + groupFiles.name + "» ?"
                            )
                          ) {
                            getTicketMain("", "delete_folder", "", index);
                          }
                        }}
                      >
                        Очистить папку
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                ))
              : false}

            {accessMain && accessMain === "change" ? (
              <div className="ticket_main_files-group ticket_main_files-group_small">
                <div
                  className="ticket_main_files-add_group"
                  onClick={() => {
                    getTicketMain("", "add_folder");
                  }}
                >
                  <IoFolderOpenOutline />
                  Добавить папку
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      ) : (
        <div>Загрузка</div>
      )}
    </>
  );
}

export default TicketMain;
