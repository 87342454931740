import $ from "jquery";
import GetData from "components/unit/servicdesk/getdata/GetData";
import logoblack from "../../image/img/logo.png";
import madeInRF from "../../image/img/made_in_rf.jpeg";
import { useEffect, useState } from "react";
import YandexMetrika from "components/metriks/YandexMetrika";
import Captcha from "./Captcha";
import Spinner from "react-bootstrap/Spinner";

function Registration() {
  const [emailIsUsed, setEmailIsUsed] = useState([]);
  const [urlCompany, setUrlCompany] = useState([]);
  const [resultKeyCaptcha, setResultKeyCaptcha] = useState(false);
  const [captchaResult, setCaptchaResult] = useState(false);
  const [inactiveButton, setInactiveButton] = useState(false);
  const [rebootCaptcha, setRebootCaptcha] = useState(false);

  async function RegistrationKey(user_name, user_phone, user_mail, company) {
    if (user_mail.length > 2) {
      const request_parameters = {
        module: "auth",
        block: "registration",
      };

      const request_attribute = "";

      const request_data = {
        user_name: user_name,
        user_phone: user_phone,
        user_mail: user_mail,
        company: company,
      };

      setInactiveButton(true);

      const result = await GetData(
        request_parameters,
        request_attribute,
        request_data
      );

      setInactiveButton(false);

      let status =
        result && result.request_data && result.request_data.status
          ? result.request_data.status
          : [];

      const url =
        result && result.request_data && result.request_data.url
          ? result.request_data.url
          : [];
      setUrlCompany(url);
      setEmailIsUsed(status);
    } else {
      console.log("her2");
    }
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.text = `(function (m, e, t, r, i, k, a) {
      m[i] = m[i] || function () {
        (m[i].a = m[i].a || []).push(arguments);
      };
      m[i].l = 1 * new Date();
      k = e.createElement(t), a = e.getElementsByTagName(t)[0];
      k.async = 1;
      k.src = r;
      a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
    
    ym(93672224, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    });`;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      {emailIsUsed !== "y" ? (
        <div className="registration">
          <div className="registration_all_content">
            <div className="registration_logo_authblock">
              <div className="registration_logoblack__text_slogan">
                <div className="registration_logoblack">
                  <div>
                    <a href="/">
                      <img src={logoblack} alt="logo" />
                    </a>
                  </div>

                  <div>
                    <img src={madeInRF} alt="made in RF" />
                  </div>
                </div>
                <div className="registration_text_slogan">
                  <div className="registration_description_text">
                    Программное обеспечение «Аритин» полностью разработано в
                    России, исключительно российскими специалистами. Серверы, на
                    которых работает система, размещаются на территории
                    Российской Федерации.
                  </div>
                  <div className="registration_aritin_made_in_rf title_h2">
                    Aritin © 2023 Сделано в РФ
                  </div>
                </div>
              </div>
              <div className="registration_block_50">
                <div className="registration_block border_background">
                  <div className="registration_block_tittle_area">
                    <div className="registration_block_tittle border_bottom_background title_h2">
                      Регистрация
                    </div>
                    <div className="target-auth">
                      <a href="/">Перейти на главную</a>
                    </div>
                  </div>
                  <div className="registration_input_block">
                    <div className="registration_textarea_name">
                      <input
                        className="registration_input_name"
                        id="name"
                        type="text"
                        placeholder="Имя"
                      ></input>
                    </div>
                    <div className="registration_textarea_email">
                      <input
                        className="registration_inputemail"
                        id="email"
                        type="email"
                        placeholder="Почта"
                      ></input>
                    </div>
                    <div className="registration_email_is_used">
                      {emailIsUsed === "email_is_used" ? (
                        <div className="registration_email_is_error">
                          <div className="registration_email_is_error_text">
                            Почта уже существует в Aritin
                          </div>
                          <div className="registration_email_is_error_target">
                            <a href="/">Перейти на главную</a>
                          </div>
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                    <div className="registration_textarea_phone">
                      <input
                        className="registration_input_phone"
                        id="phone"
                        type="phone"
                        placeholder="Телефон"
                      ></input>
                    </div>
                    <div className="registration_textarea_company">
                      <input
                        className="registration_input_company"
                        id="url"
                        type="url"
                        placeholder="Название компании"
                      ></input>
                    </div>
                    {inactiveButton === false ? (
                      <div className="block-captcha">
                        <Captcha
                          propssetResultKeyCaptcha={setResultKeyCaptcha}
                          propsresultKeyCaptcha={resultKeyCaptcha}
                          propssetCaptchaResult={setCaptchaResult}
                          rebootCaptcha={rebootCaptcha}
                          setRebootCaptcha={setRebootCaptcha}
                        />
                      </div>
                    ) : (
                      false
                    )}
                    {captchaResult ? (
                      <div className="captcharesult-text">{captchaResult}</div>
                    ) : (
                      false
                    )}
                    <div className="registration_send_button_block">
                      {inactiveButton === false ? (
                        <div
                          className="registration_send_button"
                          onClick={() => {
                            YandexMetrika("reachGoal", "click_registration");
                            var user_name = $("#name").val();
                            var user_phone = $("#phone").val();
                            var user_mail = $("#email").val();
                            var company = $("#url").val();

                            if (user_mail) {
                              if (
                                resultKeyCaptcha &&
                                resultKeyCaptcha === true
                              ) {
                                RegistrationKey(
                                  user_name,
                                  user_phone,
                                  user_mail,
                                  company
                                );
                                setCaptchaResult(false);
                              } else {
                                setCaptchaResult("Неверные символы");
                              }
                            } else {
                              setCaptchaResult("Заполните почту");
                            }

                            setRebootCaptcha(true);
                          }}
                        >
                          Зарегистрироваться
                        </div>
                      ) : (
                        <div>
                          <Spinner animation="border" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="p-2 registration_block_agreement">
                  Продолжая регистрацию, вы соглашаетесь с условиями{" "}
                  <a
                    href="https://aritin.ru/about/agreement.php"
                    target="_blank"
                  >
                    лицензионного соглашения
                  </a>{" "}
                  и{" "}
                  <a
                    href="https://aritin.ru/about/politica.php"
                    target="_blank"
                  >
                    политикой конфиденциальности
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="registration">
          <div className="registration_all_content">
            <div className="registration_logo_authblock">
              <div className="registration_logoblack__text_slogan">
                <div className="registration_logoblack">
                  <img src={logoblack} alt="logo" />
                </div>
                <div className="registration_text_slogan">
                  <div>
                    Aritin - единая цифровая платформа для управления и контроля
                    за рабочим процессом
                  </div>
                  <div>Aritin © 2023 Сделано в РФ</div>
                </div>
              </div>
              <div className="registration_block_yes border_background">
                <div className="registration_block_tittle_area">
                  <div className="registration_block_tittle border_bottom_background title_h2">
                    Данные для авторизации отправлены на почту
                  </div>
                </div>
                <div className="registration_input_block">
                  <div className="registration_textarea_name_send">
                    {YandexMetrika("reachGoal", "successful_registration")}
                    <div>Благодарим Вас за регистрацию в системе Аритин.</div>
                    <div>
                      Данные для авторизации (логин и пароль) отправлены на Вашу
                      почту.
                    </div>
                  </div>

                  <div className="registration_send_button_block_yes">
                    {urlCompany ? (
                      <div
                        className="send_button_auth_registration"
                        onClick={() => window.location.replace(urlCompany)}
                      >
                        Авторизоваться
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Registration;
