import React, { useState, useEffect, useRef } from "react";
import ContentEditable from "react-contenteditable";
import $ from "jquery";
import GetData from "components/unit/servicdesk/getdata/GetData";
import moment from "moment";
import { AiFillCheckSquare, AiOutlineCloseSquare } from "react-icons/ai";
import DOMPurify from "dompurify";

function FunctionChange({
  getValue,
  propsValue,
  fieldType,
  propsState,
  propsIditem,
  propsInput,
  propsParamData,
  access,
  propsFail,
  propsSubstitute,
}) {
  let propsStateValue = false;

  if (!access) {
    access = "change";
  }

  if (propsState === "Y") {
    propsStateValue = true;
  }

  const [failMessage, setFailMessage] = useState(propsFail);
  const [openValue, setOpenValue] = useState(propsStateValue);
  const [randomId, setRandomId] = useState(
    Math.floor(Math.random() * (99999 - 10000) + 10000)
  );
  const [openValueID, setOpenValueID] = useState();
  const [valueChange, setValueChange] = useState(propsValue);
  const [listSelector, setListSelector] = useState(false);

  useEffect(() => {
    setFailMessage(propsFail);
  }, [propsFail]);

  useEffect(() => {
    setValueChange(propsValue);
  }, [propsValue]);

  useEffect(() => {
    if (propsState === "Y") {
      setOpenValue(true);
    }
  }, [propsState]);

  useEffect(() => {
    if (openValue) {
      $("#textarea-field").focus();
      document.addEventListener("click", handleOutsideClick);
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [openValue]);

  const handleOutsideClick = (event) => {
    if (
      $(event.target).hasClass("textarea_id_" + randomId) ||
      $(event.target).hasClass("value_id_" + randomId) ||
      $(event.target).hasClass("btn_save_id_" + randomId)
    ) {
    } else {
      setOpenValue(false);
    }
  };

  useEffect(() => {
    if (!openValueID) {
      setValueChange(propsValue);
    }

    if (openValueID && openValueID > 0) {
      document.addEventListener("mousedown", handleOutsideClickNew);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClickNew);
      };
    }
  }, [openValueID]);

  const handleOutsideClickNew = (event) => {
    if ($(event.target).hasClass("value_id_" + randomId)) {
    } else {
      setOpenValueID();
      setValueChange(propsValue);
    }
  };

  async function getListSelector() {
    const request_parameters = {
      module: propsParamData.module,
      block: propsParamData.block,
    };
    const request_attribute = {
      content: { value: "name" },
      for: propsParamData.for,
      group_id: propsParamData.group_id,
    };
    const result = await GetData(request_parameters, request_attribute);

    const arrItemsSelector =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : null;

    setListSelector(arrItemsSelector);
  }

  const handleChange = (evt) => {
    let stringWithoutTags = evt.target.value.replace(/<[^>]+>/g, "");

    setValueChange(stringWithoutTags);
  };

  const handleOnFocus = (evt) => {
    if (evt.target.id === "item" + randomId) {
      setOpenValueID(randomId);
      setTimeout(() => {
        $("#item" + randomId).focus();
      }, 10);
    }
  };

  function checkedElementInArray(arr, element) {
    let currentID = 0;

    if (arr && arr.length !== 0) {
      for (let i = 0; i < arr.length; i++) {
        currentID = parseInt(arr[i].id);
        if (currentID === element) {
          return true;
        }
      }
    }

    return false;
  }

  if (
    (propsInput === "select" || propsInput === "selector") &&
    !Array.isArray(propsValue)
  ) {
    return (
      <div
        className={`functionChangeSelector ${"value_id_" + randomId} ${
          openValueID === randomId ? "active" : ""
        }`}
      >
        {openValueID === randomId && access === "change" ? (
          <div className={"functionChangeSelector-list value_id_" + randomId}>
            <div
              className="functionChangeSelector-placeHolder"
              onClick={() => {
                setOpenValueID();
              }}
            >
              Выберите значение
            </div>
            {listSelector
              ? listSelector.map((itemselector, index) => (
                  <div
                    key={index}
                    className={
                      "functionChangeSelector-items value_id_" + randomId
                    }
                    onClick={() => {
                      getValue(fieldType, itemselector.id, propsIditem);
                      setListSelector(false);
                      setOpenValueID();
                      setValueChange(itemselector.value);
                    }}
                  >
                    {itemselector.value}
                  </div>
                ))
              : false}
          </div>
        ) : (
          false
        )}
        <div
          className={" value_id_" + randomId}
          onClick={
            access === "change"
              ? () => {
                  setOpenValueID(randomId);
                  getListSelector();
                }
              : null
          }
        >
          {valueChange ? valueChange : "не заполненно"}
        </div>
      </div>
    );
  } else if (
    (propsInput === "select" || propsInput === "selector") &&
    Array.isArray(propsValue)
  ) {
    return (
      <div
        className={`functionChangeSelector ${"value_id_" + randomId} ${
          openValueID === randomId ? "active" : ""
        }`}
      >
        {openValueID === randomId && access === "change" ? (
          <div className={"functionChangeSelector-list value_id_" + randomId}>
            <div
              className="functionChangeSelector-placeHolder"
              onClick={() => {
                setOpenValueID();
              }}
            >
              Выберите значение
            </div>

            {valueChange
              ? valueChange.map((itemselector, index) => (
                  <div
                    key={index}
                    className={
                      "functionChangeSelector-items active-check value_id_" +
                      randomId
                    }
                    onClick={() => {
                      getValue(
                        fieldType,
                        itemselector.id,
                        propsIditem,
                        "delete"
                      );
                      setListSelector(false);
                      setOpenValueID();
                      setValueChange(itemselector.value);
                    }}
                  >
                    {itemselector.value}
                  </div>
                ))
              : false}

            {listSelector
              ? listSelector.map((itemselector, index) =>
                  checkedElementInArray(valueChange, itemselector.id) ? (
                    false
                  ) : (
                    <div
                      key={index}
                      className={
                        "functionChangeSelector-items value_id_" + randomId
                      }
                      onClick={() => {
                        getValue(
                          fieldType,
                          itemselector.id,
                          propsIditem,
                          "edit"
                        );
                        setListSelector(false);
                        setOpenValueID();
                        setValueChange(itemselector.value);
                      }}
                    >
                      {itemselector.value}
                    </div>
                  )
                )
              : false}
          </div>
        ) : (
          false
        )}
        <div
          className={" value_id_" + randomId}
          onClick={
            access === "change"
              ? () => {
                  setOpenValueID(randomId);
                  getListSelector();
                }
              : null
          }
        >
          {valueChange && Array.isArray(valueChange) && valueChange.length > 0
            ? valueChange.map((valuechangevalue, index) =>
                valuechangevalue ? (
                  <div key={index}>
                    <div
                      className={`functionChangeSelector ${
                        "value_id_" + randomId
                      }`}
                    >
                      {valuechangevalue.value}
                    </div>
                  </div>
                ) : (
                  false
                )
              )
            : "не заполненно"}
        </div>
      </div>
    );
  } else if (propsInput === "date") {
    return (
      <>
        {openValueID === randomId ? (
          <div className={"value_id_" + randomId}>
            <input
              id="dateInputTicketControlPanel"
              className={"value_id_" + randomId}
              type="date"
              onChange={handleChange}
              defaultValue={`${moment(valueChange, "YYYY.MM.DD").format(
                "YYYY-MM-DD"
              )}`}
            ></input>
            <div>
              <div
                onClick={() => {
                  getValue(fieldType, valueChange, propsIditem);
                  setOpenValueID();
                }}
              >
                <AiFillCheckSquare />
              </div>
              <div
                onClick={() => {
                  setOpenValueID();
                  setValueChange(propsValue);
                }}
              >
                <AiOutlineCloseSquare />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={"value_id_" + randomId}
            onClick={() => {
              setOpenValueID(randomId);
            }}
          >
            {`${moment(valueChange, "YYYY.MM.DD").format("DD.MM.YYYY")}`}
          </div>
        )}
      </>
    );
  } else if (propsInput === "date_fix") {
    return valueChange && valueChange !== "0000-00-00 00:00:00" ? (
      <div className={"value_id_" + randomId}>{`${moment(
        valueChange,
        "YYYY.MM.DD"
      ).format("DD.MM.YYYY")}`}</div>
    ) : (
      "нет информации"
    );
  } else {
    return (
      <div
        className={`functionChange ${openValueID === randomId ? "active" : ""}`}
      >
        <>
          <ContentEditable
            id={"item" + randomId}
            className={"functionChange-article value_id_" + randomId}
            html={valueChange ? valueChange : ""}
            /*disabled={true}*/

            disabled={openValueID === randomId ? false : true}
            tagName="article"
            onChange={handleChange}
            onClick={access === "change" ? handleOnFocus : null}
          />

          {!valueChange && openValueID !== randomId ? (
            <div
              className={"functionChange-placeholder value_id_" + randomId}
              onClick={() => {
                setOpenValueID(randomId);
              }}
            >
              Не заполненно
            </div>
          ) : (
            false
          )}
          {access === "change" ? (
            <>
              {openValueID === randomId ? (
                <div className="functionChange-button">
                  {$("#item" + randomId).width() > 190 ? (
                    <div
                      className={"functionChange-cansel value_id_" + randomId}
                      onClick={() => {
                        setOpenValueID();
                        setValueChange(propsValue);
                      }}
                    >
                      Отменить
                    </div>
                  ) : (
                    false
                  )}

                  <div
                    className={"functionChange-save value_id_" + randomId}
                    onClick={() => {
                      getValue(fieldType, valueChange, propsIditem);
                      setOpenValueID();
                    }}
                  >
                    Сохранить
                  </div>
                </div>
              ) : (
                false
              )}
            </>
          ) : (
            false
          )}
        </>
      </div>
    );
  }
}

export default FunctionChange;
