import { useEffect } from "react";
import { IoCaretForward, IoCaretBack, IoCloseOutline } from "react-icons/io5";
import $ from "jquery";

const GallerySlider = ({
  galleryImages,
  galleryImageIndex,
  setGalleryImageIndex,
}) => {
  const nextImage = () => {
    setGalleryImageIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setGalleryImageIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    document.addEventListener("click", clickListener);

    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, []);

  const clickListener = (e) => {
    if ($(e.target).hasClass("gallery_images_block")) {
      setGalleryImageIndex("");
    }
  };

  return galleryImages &&
    galleryImages.length > 0 &&
    galleryImageIndex !== "" ? (
    <div className="gallery_images_block">
      <div className="gallery_images_content">
        {galleryImages.length > 1 ? (
          <div>
            <div className="gallery_images_onclick prev" onClick={prevImage}>
              <IoCaretBack />
            </div>
            <div className="gallery_images_onclick next" onClick={nextImage}>
              <IoCaretForward />
            </div>
          </div>
        ) : (
          false
        )}

        <img
          src={galleryImages[galleryImageIndex].url}
          alt={`Image ${galleryImageIndex}`}
        />
        <div
          className="gallery_images_btn_close"
          onClick={() => setGalleryImageIndex("")}
        >
          <IoCloseOutline />
        </div>
      </div>
    </div>
  ) : (
    false
  );
};

export default GallerySlider;
