import { useEffect, useState } from "react";
import getData from "../getdata/GetData";
import TicketFastInfo from "./TicketFastInfo";
import AritinEditor from "components/general/AritinEditor";
import FunctionChange from "components/general/FunctionChange";
import GallerySlider from "components/general/GallerySlider";
import Spinner from "react-bootstrap/Spinner";
import {
  IoClose,
  IoDuplicateOutline,
  IoDocumentOutline,
} from "react-icons/io5";

function TicketsFastMain({ id_ticket_fast, setTicketStatusCode }) {
  const [listTicketsFastMain, setlistTicketsFastMain] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImageIndex, setGalleryImageIndex] = useState("");
  const [download, setDownload] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [changeField, setChangeField] = useState(false);

  function addOnForm(changeFile) {
    if (changeFile && changeFile.target && changeFile.target.files[0]) {
      let arrFilesAll = Array.from(changeFile.target.files);
      getTicketsFastMain("", "add_files", "", arrFilesAll);
    }
  }

  const getTicketsFastMain = async (action, edit_field, value, arrFilesAll) => {
    const request_parameters = { module: "sd", block: "fast_tickets_main" };
    const request_attribute = {
      action: action,
      id_ticket_fast: id_ticket_fast,
    };
    const request_data = {
      edit_field,
      value,
    };

    let result = [];
    if (edit_field === "add_files") {
      setDownload(true);
      result = await getData(
        request_parameters,
        request_attribute,
        request_data,
        arrFilesAll
      );
      setDownload(false);
    } else {
      result = await getData(
        request_parameters,
        request_attribute,
        request_data
      );
    }

    const arrItemsTicketsFastMain =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    if (
      result &&
      result.request_attribute &&
      result.request_attribute.status_code
    ) {
      setTicketStatusCode(result.request_attribute.status_code);
    }

    setlistTicketsFastMain(arrItemsTicketsFastMain);
  };

  useEffect(() => {
    getTicketsFastMain();
  }, []);

  useEffect(() => {
    if (upgrade && upgrade === true) {
      getTicketsFastMain();
      setUpgrade(false);
    }
  }, [upgrade]);

  function saveFastDescription(textValue) {
    getTicketsFastMain("save", "description", textValue);
  }

  function saveFastSubject(fieldType, textValue) {
    getTicketsFastMain("save", fieldType, textValue);
  }

  return (
    <>
      {listTicketsFastMain ? (
        <div className="ticketfastmain">
          <div className="container">
            <div className="row">
              <div className="col-sm-9">
                <div className="border_background p-3">
                  <div className="ticketfastmain-name-number">
                    <div className="a-text-3 pb-2">
                      {listTicketsFastMain.subject &&
                      listTicketsFastMain.subject.access &&
                      listTicketsFastMain.subject.access === "change" ? (
                        <FunctionChange
                          getValue={saveFastSubject}
                          propsValue={
                            listTicketsFastMain.subject.value
                              ? listTicketsFastMain.subject.value
                              : "нет данных"
                          }
                          fieldType={"subject"}
                        />
                      ) : listTicketsFastMain.subject &&
                        listTicketsFastMain.subject.value ? (
                        listTicketsFastMain.subject.value
                      ) : (
                        ""
                      )}
                    </div>
                    <div className=" pb-3">
                      {listTicketsFastMain.id &&
                      listTicketsFastMain.id.value ? (
                        <div className="border_bg_b_c_nine ticketfastmain-number-title">
                          Заявка № {listTicketsFastMain.id.value}
                        </div>
                      ) : (
                        "Загрузка"
                      )}
                    </div>
                  </div>

                  <div className="ticketfastmain-description aritin-editor-box">
                    {listTicketsFastMain.description &&
                    listTicketsFastMain.description.access &&
                    listTicketsFastMain.description.access === "change" ? (
                      <AritinEditor
                        textProps={
                          listTicketsFastMain.description.value
                            ? listTicketsFastMain.description.value
                            : ""
                        }
                        saveTextProps={saveFastDescription}
                        setChangeField={setChangeField}
                        changeField={changeField}
                        propsAccess={listTicketsFastMain.description.access}
                      />
                    ) : listTicketsFastMain.description &&
                      listTicketsFastMain.description.value ? (
                      listTicketsFastMain.description.value
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <GallerySlider
                      galleryImages={galleryImages}
                      galleryImageIndex={galleryImageIndex}
                      setGalleryImageIndex={setGalleryImageIndex}
                    />
                  </div>
                  {listTicketsFastMain &&
                  listTicketsFastMain.files &&
                  listTicketsFastMain.files.image ? (
                    <div className="ticket_main_files-image_items">
                      {listTicketsFastMain.files.image.map(
                        (fileTicketsFastImage, indexImage) => (
                          <div
                            key={indexImage}
                            className="ticket_main_files-image_item"
                          >
                            <a>
                              <img
                                src={fileTicketsFastImage.url}
                                onClick={() => {
                                  setGalleryImages(
                                    listTicketsFastMain.files.image
                                  );
                                  setGalleryImageIndex(indexImage);
                                }}
                              />
                            </a>
                            <div
                              className="ticket_main_files-image_del"
                              onClick={() => {
                                if (window.confirm("Удалить файл?")) {
                                  getTicketsFastMain(
                                    "",
                                    "delete_files",
                                    fileTicketsFastImage.id
                                  );
                                }
                              }}
                            >
                              <IoClose />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    false
                  )}
                  {listTicketsFastMain &&
                  listTicketsFastMain.files &&
                  listTicketsFastMain.files.other
                    ? listTicketsFastMain.files.other.map(
                        (fileTicketsFastOther, indexOther) => (
                          <div
                            key={indexOther}
                            className="ticket_main_files-other_item"
                          >
                            <div className="ticket_main_files-other_name">
                              <a
                                href={fileTicketsFastOther.url}
                                target="_blank"
                              >
                                {fileTicketsFastOther.name}
                              </a>
                              <div className="ticket_main_files-other_icon">
                                <a
                                  href={fileTicketsFastOther.url}
                                  target="_blank"
                                >
                                  <IoDocumentOutline />
                                </a>
                              </div>
                              <div className="ticket_main_files-other_size">
                                {fileTicketsFastOther.size}
                              </div>
                              <div
                                className="ticket_main_files-other_del"
                                onClick={() => {
                                  if (window.confirm("Удалить?")) {
                                    getTicketsFastMain(
                                      "",
                                      "delete_files",
                                      fileTicketsFastOther.id
                                    );
                                  }
                                }}
                              >
                                <IoClose />
                              </div>
                            </div>
                          </div>
                        )
                      )
                    : false}
                  <div className="ticket_main_files-other_item">
                    <div className="ticket_main_files-other_name">
                      {download ? (
                        <div className="ticket_main_files-other_download">
                          <div className="">Загрузка файла</div>
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                  <div className="ticket_main_files-add_file">
                    <form method="post" encType="multipart/form-data">
                      <label htmlFor={"input_id_"}>
                        <IoDuplicateOutline className="ticket_main_files-add_file_icon" />
                        Добавить файл
                      </label>
                      <input
                        className="class_display_none"
                        id={"input_id_"}
                        type="file"
                        multiple="multiple"
                        onChange={addOnForm}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <TicketFastInfo
                  id_ticket_fast={id_ticket_fast}
                  propsSetUpgrade={setUpgrade}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
}

export default TicketsFastMain;
