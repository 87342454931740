import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import getData from "../getdata/GetData";
import Branch from "./Branch";
import СounterpartieContacts from "./СounterpartieContacts";
import { arrConstCounterpartie } from "../const/ConstTicket";
import FunctionChange from "components/general/FunctionChange";
import { IoCameraReverseOutline } from "react-icons/io5";
import PlugAccess from "components/general/PlugAccess";
import NotPaid from "components/general/NotPaid";
import NotFound from "components/general/NotFound";
import Unauthorized from "components/general/Unauthorized";

function Сounterpartie() {
  let { id } = useParams();

  const [arrCounterpartie, setArrCounterpartie] = useState([]);
  const [changeFieldValue, setChangeFieldValue] = useState(false);
  const [ticketStatusCode, setTicketStatusCode] = useState("");
  useEffect(() => {
    listCounterpartie();
  }, []);

  const listCounterpartie = async (field, textareaValue, file) => {
    const request_parameters = {
      module: "settings",
      block: "projects",
      type: "get",
    };
    const request_attribute = {
      id_item: id,
    };

    const request_data = {
      edit_field: field,
      value: textareaValue,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data,
      file
    );

    if (
      result &&
      result.request_attribute &&
      result.request_attribute.status_code
    ) {
      setTicketStatusCode(result.request_attribute.status_code);
    }

    const arrListCounterpartie =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setArrCounterpartie(arrListCounterpartie);
    setChangeFieldValue(false);
  };

  function getValue(fieldType, value) {
    listCounterpartie(fieldType, value);
  }

  // FUNCTION SEND
  const getFileForSend = async (event) => {
    listCounterpartie("image", "", event.target.files);
  };

  return (
    <>
      {ticketStatusCode === "404" ? (
        <>
          <NotFound />
        </>
      ) : ticketStatusCode === "403" ? (
        <PlugAccess />
      ) : ticketStatusCode === "402" ? (
        <NotPaid />
      ) : ticketStatusCode === "401" ? (
        <Unauthorized />
      ) : (
        <div className="counterpartie">
          {arrCounterpartie ? (
            <div className="container">
              <div className="row">
                {/* Name Start */}
                <div className="col-12">
                  <div className="a-text-1 a-section-title">
                    {arrCounterpartie &&
                    arrCounterpartie.name &&
                    arrCounterpartie.name.value
                      ? arrCounterpartie.name.value
                      : false}
                  </div>
                </div>

                {/* Name End */}

                <div className="col-3">
                  <div className="border_background p-4 counterpartie-sticky">
                    {/* image Start */}
                    <div className="border_bg_b_c_three pb-4">
                      <div className="counterpartie-image a-relative">
                        {arrCounterpartie &&
                        arrCounterpartie.image &&
                        arrCounterpartie.image.value ? (
                          <img src={arrCounterpartie.image.value}></img>
                        ) : (
                          "Добавить логотип"
                        )}

                        <div className="">
                          <form>
                            <input
                              className="class_display_none"
                              id="btnFromAddFileCounterpartie"
                              type="file"
                              onChange={getFileForSend}
                            />
                            <label
                              className="counterpartie_image_label"
                              htmlFor="btnFromAddFileCounterpartie"
                            >
                              <IoCameraReverseOutline />
                            </label>
                          </form>
                        </div>
                      </div>
                      {/* image End */}
                    </div>

                    {/* project_manager Start */}
                    <div className="border_bg_b_c_three pb-4 pt-4 a-flex a-gap-20">
                      <div className="project-manager-image-block">
                        <div className="project-manager-image">
                          {arrCounterpartie.project_manager &&
                          arrCounterpartie.project_manager.image ? (
                            <div>
                              <img
                                src={arrCounterpartie.project_manager.image}
                              ></img>
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                      </div>

                      <div className="project-manager-name-block">
                        <div className="a-text-4">
                          {arrConstCounterpartie.project_manager.title}
                        </div>

                        {arrCounterpartie &&
                        arrCounterpartie.project_manager ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              arrCounterpartie.project_manager.value
                                ? arrCounterpartie.project_manager.value
                                : ""
                            }
                            fieldType="project_manager"
                            propsInput="select"
                            propsParamData={{
                              module: "settings",
                              block: "users",
                            }}
                          />
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                    {/* project_manager End */}
                    {/* Name Start */}
                    <div className="left-block-bottom pt-4">
                      <div className="pb-4">
                        <div className="a-text-4">
                          {arrConstCounterpartie.name.title}
                        </div>

                        {arrCounterpartie &&
                        arrCounterpartie.name &&
                        arrCounterpartie.name.value ? (
                          <FunctionChange
                            getValue={getValue}
                            fieldType={arrCounterpartie.name.field}
                            propsValue={arrCounterpartie.name.value}
                          />
                        ) : (
                          false
                        )}
                      </div>
                      {/* Name End */}

                      {/* Phone Start */}
                      <div className="pb-4">
                        <div className="a-text-4">
                          {arrConstCounterpartie.phone.title}
                        </div>
                        {arrCounterpartie &&
                        arrCounterpartie.phone &&
                        arrCounterpartie.phone.field ? (
                          <FunctionChange
                            getValue={getValue}
                            fieldType={arrCounterpartie.phone.field}
                            propsValue={
                              arrCounterpartie.phone.value
                                ? arrCounterpartie.phone.value
                                : ""
                            }
                          />
                        ) : (
                          false
                        )}
                      </div>
                      {/* Phone End */}
                      {/* website Start */}
                      <div className="pb-4">
                        <div className="a-text-4">
                          {arrConstCounterpartie.website.title}
                        </div>
                        {arrCounterpartie &&
                        arrCounterpartie.website &&
                        arrCounterpartie.website.field ? (
                          <FunctionChange
                            getValue={getValue}
                            fieldType={arrCounterpartie.website.field}
                            propsValue={
                              arrCounterpartie.website.value
                                ? arrCounterpartie.website.value
                                : ""
                            }
                          />
                        ) : (
                          false
                        )}
                      </div>
                      {/* website End */}
                      {/* type_counterpartie Start */}
                      <div className="pb-4">
                        <div className="a-text-4">
                          {arrConstCounterpartie.type_counterpartie.title}
                        </div>
                        {arrCounterpartie &&
                        arrCounterpartie.type_counterpartie &&
                        arrCounterpartie.type_counterpartie.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              arrCounterpartie.type_counterpartie.value
                            }
                            fieldType={
                              arrCounterpartie.type_counterpartie.field
                            }
                            propsInput="select"
                            propsParamData={{
                              module: "settings",
                              block: "type_counterpartie",
                            }}
                          />
                        ) : (
                          false
                        )}
                      </div>
                      {/* type_counterpartie End */}
                      {/* EDO Start */}
                      <div className="pb-4">
                        <div className="a-text-4">
                          {arrConstCounterpartie.edo.title}
                        </div>
                        {arrCounterpartie && arrCounterpartie.edo ? (
                          <FunctionChange
                            getValue={getValue}
                            fieldType="edo"
                            propsInput="select"
                            propsValue={
                              arrCounterpartie.edo.value
                                ? arrCounterpartie.edo.value
                                : ""
                            }
                            propsParamData={{
                              module: "settings",
                              block: "edo",
                            }}
                          />
                        ) : (
                          false
                        )}
                      </div>

                      {/* EDO End */}
                    </div>
                  </div>
                </div>

                <div className="col-9">
                  {/* requisites map Start */}

                  <div className="row">
                    <div className="col-12 mb-4">
                      <div className="border_background p-4">
                        <div className="border_bg_b_c_three a-text-2 pb-2">
                          Банковские реквизиты
                        </div>
                        <div className="pt-4 right-block-main">
                          {arrCounterpartie && arrCounterpartie.requisites
                            ? arrCounterpartie.requisites.map(
                                (counterpartievalue, index) =>
                                  counterpartievalue &&
                                  counterpartievalue.field ? (
                                    <div className="pb-4" key={index}>
                                      <div className="a-text-4">
                                        {
                                          arrConstCounterpartie[
                                            counterpartievalue.field
                                          ].title
                                        }
                                      </div>
                                      <FunctionChange
                                        getValue={getValue}
                                        fieldType={counterpartievalue.field}
                                        propsValue={counterpartievalue.value}
                                      />
                                    </div>
                                  ) : (
                                    false
                                  )
                              )
                            : false}
                        </div>
                      </div>
                    </div>

                    {/* requisites map End */}

                    <div className="col-6 mb-3">
                      <СounterpartieContacts />
                    </div>
                    <div className="col-6 mb-3">
                      <Branch />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            false
          )}
        </div>
      )}
    </>
  );
}

export default Сounterpartie;
