import { useEffect, useState } from "react";
import getData from "../getdata/GetData";
import { arrSelectors } from "../const/DataControlPanel";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import $ from "jquery";
import FunctionChange from "components/general/FunctionChange";

function TicketsFastInfo({ id_ticket_fast, propsSetUpgrade }) {
  const [listTicketsFastInfo, setlistTicketsFastInfo] = useState([]);

  const getTicketsFastInfo = async (action, edit_field, value) => {
    const request_parameters = { module: "sd", block: "fast_tickets_info" };
    const request_attribute = {
      action: action,

      id_ticket_fast: id_ticket_fast,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrItemsTicketsFastInfo =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setlistTicketsFastInfo(arrItemsTicketsFastInfo);

    if (edit_field === "status") {
      propsSetUpgrade(true);
    }
  };

  useEffect(() => {
    if (id_ticket_fast > 0) {
      getTicketsFastInfo();
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [id_ticket_fast]);

  const handleOutsideClick = (event) => {
    if (
      $(event.target).hasClass("even-button-more-no-active") ||
      $(event.target).hasClass("even-button-more-active") ||
      $(event.target).hasClass("even-button-more") ||
      $(event.target).hasClass("even-button-more-btn") ||
      $(event.target).parent().hasClass("even-button-more-btn")
    ) {
      $(".even-button-other-statuses").toggleClass(
        "even-button-other-statuses-active"
      );
    }

    if (
      !event.target.closest(".even-button-other-statuses") &&
      !event.target.closest(".even-button-more")
    ) {
      $(".even-button-other-statuses").removeClass(
        "even-button-other-statuses-active"
      );
      $(".even-button-more").removeClass("active-even-button");
    }
  };

  function getValue(edit_field, value, msproper, action) {
    let action2 = action;
    if (action2 !== "delete" && action2 !== "edit") {
      action2 = "edit";
    }

    getTicketsFastInfo(action2, edit_field, value);
  }

  return (
    <>
      {listTicketsFastInfo ? (
        <div>
          <div className="ticketfastinfo border_background p-4">
            {listTicketsFastInfo.hot_block ? (
              <div className="a-relative">
                <div
                  className={
                    ` status` + listTicketsFastInfo.hot_block.status.id
                  }
                >
                  <div className="status_online">
                    {listTicketsFastInfo.hot_block.status.value}
                  </div>
                </div>

                <div
                  className={`a-flex a-gap-20 pb-4 ${
                    listTicketsFastInfo.hot_block.more &&
                    listTicketsFastInfo.hot_block.more.length > 0
                      ? ""
                      : "nogap"
                  }`}
                >
                  <div className="status-next-one">
                    {listTicketsFastInfo.hot_block.button &&
                    listTicketsFastInfo.hot_block.button.value &&
                    listTicketsFastInfo.hot_block.button.value !== "" ? (
                      <div
                        className="status-next-one-button"
                        onClick={() =>
                          getTicketsFastInfo(
                            "edit",
                            "status",
                            listTicketsFastInfo.hot_block.button.id
                          )
                        }
                      >
                        {listTicketsFastInfo.hot_block.button.value}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  {listTicketsFastInfo.hot_block.more &&
                  listTicketsFastInfo.hot_block.more.length > 0 ? (
                    <div className="even-block-area">
                      <div className="even-block">
                        <div className="even-button even-button-more">
                          <span className="even-button-more-no-active">
                            <IoChevronDownOutline className="even-button-more-btn" />
                          </span>
                          <span className="even-button-more-active">
                            <IoChevronUpOutline className="even-button-more-btn" />
                          </span>
                        </div>
                        <div className="even-button-other-statuses">
                          {listTicketsFastInfo.hot_block.more.map(
                            (hot_button_item, index) => (
                              <div
                                key={index}
                                className="control-panel-hot-button-more-value"
                                onClick={() => {
                                  getTicketsFastInfo(
                                    "edit",
                                    "status",
                                    hot_button_item.id
                                  );
                                }}
                              >
                                {listTicketsFastInfo.hot_block.button.value
                                  ? hot_button_item.value
                                  : false}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            ) : (
              false
            )}

            {listTicketsFastInfo.fields
              ? Object.keys(listTicketsFastInfo.fields).map(
                  (listticketsfastinfovalue, index) => {
                    const items =
                      listTicketsFastInfo.fields[listticketsfastinfovalue];
                    const selector = arrSelectors[listticketsfastinfovalue];

                    if (
                      listticketsfastinfovalue !== "date_close" &&
                      items.value !== "0000-00-00 00:00:00"
                    ) {
                      return (
                        <div key={index}>
                          <div className="pb-2">
                            <div className="a-text-4">{selector.title} </div>
                            <div>
                              <FunctionChange
                                getValue={getValue}
                                propsValue={
                                  items && items.value
                                    ? items.value
                                    : "не заполнено"
                                }
                                fieldType={listticketsfastinfovalue}
                                propsInput={items.type}
                                access={items.access}
                                propsParamData={{
                                  module: selector.module,
                                  block: selector.block,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                )
              : false}
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
}

export default TicketsFastInfo;
