import { useState, useEffect } from "react";
import "../../../../css/notes.css";
import getData from "../getdata/GetData";
import $ from "jquery";
import AritinEditor from "components/general/AritinEditor";

function Notes({ idTicket }) {
  const [notesTab, setNotesTab] = useState([]); // Общие данные
  const [download, setDownload] = useState(false); // ожидание получении данных
  const [changeField, setChangeField] = useState(false); // Заглушка для Аритинэдитор
  const [openEditerPrivate, setOpenEditerPrivate] = useState(false); // Открытие эдитора в линых заметках
  const [openEditerPublic, setOpenEditerPublic] = useState(false); // Открытие эдитора в общих заметках

  useEffect(() => {
    getNotes();
  }, []);

  async function getNotes(action, value, type) {
    setDownload(true);
    const request_parameters_adil = {
      module: "sd",
      block: "ticket_tabs",
    };

    const request_attribute_adil = {
      id_ticket: idTicket,
      current: "notes",
      action,
      type,
    };

    const request_data_adil = {
      value,
    };

    const result = await getData(
      request_parameters_adil,
      request_attribute_adil,
      request_data_adil
    );

    const arrNotes =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setNotesTab(arrNotes);
    setDownload(false);
  }

  function editNotesTabvalue(textValue, emptyOne, emptyTwo, propsVarOne) {
    getNotes("edit", textValue, propsVarOne);
  }

  return (
    <>
      <div className="a-flex">
        <div className="notes-block-left">
          <div className="notes-block-title">Личная заметка</div>
        </div>
        <div className="notes-block-right">
          <div className="notes-block-title">Общая заметка</div>
        </div>
      </div>
      {!download ? (
        <div className="a-flex notes-block">
          <div className="notes-block-left">
            {notesTab &&
            notesTab.notes_private &&
            notesTab.notes_private.value ? (
              <div className="input-ticket-box-text">
                <div className="comment-message a-link">
                  <div className="comment-user-date pb-2">
                    <div className="comment-date">
                      {notesTab.notes_private.date
                        ? notesTab.notes_private.date
                        : false}
                    </div>
                  </div>
                  <AritinEditor
                    saveTextProps={editNotesTabvalue}
                    textProps={notesTab.notes_private.value}
                    propsAccess={"change"}
                    changeField={changeField}
                    setChangeField={setChangeField}
                    propsVarOne={"private"}
                    setOpenEditer={setOpenEditerPrivate}
                  />
                </div>

                <div>
                  {!openEditerPrivate ? (
                    <div
                      className="notes-button-delete"
                      onClick={() => {
                        getNotes("delete", "", "private");
                      }}
                    >
                      Удалить
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="input-ticket-box-text">
                  <textarea
                    className=""
                    id="textarea-notes"
                    rows="5"
                    defaultValue={""}
                  />
                </div>

                <div
                  className="notes-button"
                  onClick={() => {
                    let textareaValue = $("#textarea-notes").val();
                    getNotes("add", textareaValue, "private");
                    $("#textarea-notes").val("");
                  }}
                >
                  Добавить личную заметку
                </div>
              </>
            )}
          </div>

          <div className="notes-block-right">
            {notesTab &&
            notesTab.notes_public &&
            notesTab.notes_public.value ? (
              <div className="input-ticket-box-text">
                <div className="comment-message a-link">
                  <div className="comment-user-date pb-2">
                    <div className="comment-user">
                      {notesTab.notes_public.user
                        ? notesTab.notes_public.user
                        : false}
                    </div>
                    <div className="comment-date">
                      {notesTab.notes_public.date
                        ? notesTab.notes_public.date
                        : false}
                    </div>
                  </div>

                  <AritinEditor
                    saveTextProps={editNotesTabvalue}
                    textProps={notesTab.notes_public.value}
                    propsAccess={"change"}
                    changeField={changeField}
                    setChangeField={setChangeField}
                    propsVarOne={"public"}
                    setOpenEditer={setOpenEditerPublic}
                  />
                </div>

                {!openEditerPublic ? (
                  <div>
                    <div
                      className="notes-button-delete"
                      onClick={() => {
                        getNotes("delete", "", "public");
                      }}
                    >
                      Удалить
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            ) : (
              <>
                <div className="input-ticket-box-text">
                  <textarea
                    className=""
                    id="textarea-notes-add"
                    rows="5"
                    defaultValue={""}
                  />
                </div>

                <div
                  className="notes-button"
                  onClick={() => {
                    let textareaValue = $("#textarea-notes-add").val();
                    getNotes("add", textareaValue, "public");
                    $("#textarea-notes-add").val("");
                  }}
                >
                  Добавить общую заметку
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="a-flex notes-block">
            <div className="notes-block-left">
              <div className="input-ticket-box-text">
                <div className="preloader-download">
                  <div className="circle">
                    <div className="inner">Загрузка</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="notes-block-right">
              <div className="input-ticket-box-text">
                <div className="preloader-download">
                  <div className="circle">
                    <div className="inner">Загрузка</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Notes;
