function PlugAccess() {
  return (
    <>
      <div className="p-4">
        <div className="a-text-1 pb-4">Нет доступа</div>
        <div className="a-text-3 pb-2">
          Доступ к запрашиваемой странице ограничен.
        </div>
        <div>
          Для получения доступа необходимо наличие соответствующих прав.
        </div>
        <div>
          Если вы считаете, что это ошибка, пожалуйста, свяжитесь с
          администратором системы Aritin для уточнения информации о вашем
          статусе доступа или возможных ограничениях.
        </div>
      </div>
    </>
  );
}

export default PlugAccess;
