import { useEffect, useState } from "react";
import getData from "../servicdesk/getdata/GetData";
import { useParams } from "react-router-dom";

import ArticleTitleCat from "./ArticleTitleCat";
import KnowledgeCat from "./KnowledgeCat";

function KnowledgeCategories({ nameArticle, setNameArticle }) {
  let { id } = useParams();
  const [knowCategories, setKnowCategories] = useState([]);
  const [knowArticles, setKnowArticles] = useState([]);
  const [idCategories, setIdCategories] = useState(null);
  const [accessKnow, setAccessKnow] = useState("read");

  useEffect(() => {
    getKnowledge();
  }, []);

  useEffect(() => {
    if (nameArticle === true) {
      getKnowledge();
    }
  }, [nameArticle]);

  const getKnowledge = async (action, id_item, edit_field, value) => {
    const request_parameters = {
      module: "guide",
      block: "categories",
    };
    const request_attribute = {
      action,
      id_item,
      id_art: id,
    };

    const request_data = {
      edit_field,
      value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    setNameArticle(false);

    const accessResult =
      result && result.request_data && result.request_data.access
        ? result.request_data.access
        : "read";

    const newArt =
      result &&
      result.request_data &&
      result.request_data.items &&
      result.request_data.items.new_art
        ? result.request_data.items.new_art
        : 0;

    if (newArt && newArt > 0) {
      window.location.replace("/knowledge/" + newArt + "/");
    }

    const idCat =
      result &&
      result.request_data &&
      result.request_data.items &&
      result.request_data.items.current_cat
        ? result.request_data.items.current_cat
        : null;

    const arrListCategories =
      result &&
      result.request_data &&
      result.request_data.items &&
      result.request_data.items.categories
        ? result.request_data.items.categories
        : [];
    const arrListArticles =
      result &&
      result.request_data &&
      result.request_data.items &&
      result.request_data.items.articles
        ? result.request_data.items.articles
        : [];

    setKnowCategories(arrListCategories);
    setKnowArticles(arrListArticles);
    setIdCategories(idCat);
    setAccessKnow(accessResult);
  };

  function getValue(left_fig, value, id_item) {
    getKnowledge("edit_cat", id_item, "name", value);
  }

  return (
    <>
      <div className="">
        <div>
          {knowCategories
            ? knowCategories.map((knowcategoriesmap, index) => (
                <KnowledgeCat
                  key={index}
                  knowcategoriesmap={knowcategoriesmap}
                  index={index}
                  getValue={getValue}
                  getKnowledge={getKnowledge}
                  knowArticles={knowArticles}
                />
              ))
            : false}
        </div>
        <div className="knowledge-without-categories">
          <div className="knowledge-without-categories-title">
            Без категорий
          </div>
          <div className="knowledge-without-categories-article">
            {knowArticles
              ? knowArticles
                  .filter(
                    (knowarticlesfilter) =>
                      knowarticlesfilter.categorie.value === 0
                  )
                  .map((articlerticlesmap, index2) => (
                    <ArticleTitleCat
                      key={index2}
                      articlerticlesmap={articlerticlesmap}
                      index3={index2}
                    />
                  ))
              : false}
          </div>
        </div>
        <div className="add-block-knowledge-art-cat">
          <div
            className="btn_block_add-knowledge"
            onClick={() => getKnowledge("add_cat")}
          >
            + категорию
          </div>
          <div
            className="btn_block_add-knowledge"
            onClick={() => getKnowledge("add_art", idCategories)}
          >
            + статью
          </div>
        </div>
      </div>
    </>
  );
}

export default KnowledgeCategories;
