import $ from "jquery";
import md5 from "md5";
import GetData from "components/unit/servicdesk/getdata/GetData";
import logoWhite from "../../image/png/logo_white.png";
import { useState, useEffect } from "react";
import Captcha from "./Captcha";
import Spinner from "react-bootstrap/Spinner";

function Auth() {
  const [authState, setAuthState] = useState(false);
  const [statusForgotPassword, setStatusForgotPassword] = useState(false);
  const [failText, setFailText] = useState(false);
  const [checkAuth, setCheckAuth] = useState(false);
  const [resultKeyCaptcha, setResultKeyCaptcha] = useState(false);
  const [captchaResult, setCaptchaResult] = useState(false);
  const [inputError, setInputError] = useState([]);
  const [rebootCaptcha, setRebootCaptcha] = useState(false);
  const [inactiveButton, setInactiveButton] = useState(false);

  useEffect(() => {
    redirectAfterAuth();
  }, [checkAuth]);

  useEffect(() => {
    document.addEventListener("keydown", keydownHandlerAuth);
  });

  function redirectAfterAuth() {
    const currentUrl = window.location.href;
    const containsLocation = currentUrl.includes("localhost");

    if (checkAuth && checkAuth.url && checkAuth.url.length > 0) {
      if (containsLocation) {
        window.location.replace("http://localhost:3000/task/");
      } else {
        window.location.replace(checkAuth.url + "task/");
      }
    }
  }

  async function AuthKey(login, password) {
    if (login.length > 2 && password.length > 4) {
      let password2 = md5(password);

      const request_parameters = {
        module: "auth",
        block: "auth",
      };
      const request_attribute = {
        sd: [],
      };
      let request_data = {
        var_one: login,
        var_two: password2,
      };

      setInactiveButton(true);
      const result = await GetData(
        request_parameters,
        request_attribute,
        request_data
      );

      setInactiveButton(false);

      const arrInputError =
        result &&
        result.request_data &&
        result.request_data.items &&
        result.request_data.items.status_text
          ? result.request_data.items.status_text
          : [];

      const token_url =
        result && result.request_data ? result.request_data : [];

      setCheckAuth(token_url);
      setInputError(arrInputError);

      if (token_url.fail === "y") {
        setFailText("Неверный логин или пароль");
      }
    } else {
      setFailText("Неверный логин или пароль");
    }
  }

  async function AuthKeyRecover(value) {
    if (value.length > 2) {
      const request_parameters = {
        module: "auth",
        block: "forgot_password_1",
      };

      const request_attribute = {
        action: "action_1",
      };

      const request_data = {
        value: value,
      };

      const result = await GetData(
        request_parameters,
        request_attribute,
        request_data
      );

      const recover =
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [];
      setStatusForgotPassword(recover);
    } else {
      console.log("her");
    }
  }

  const keydownHandlerAuth = (event) => {
    if (event.which === 13) {
      var login = $("#login").val();
      var password = $("#password").val();
      AuthKey(login, password);
    }
  };

  return (
    <>
      <div className="auth">
        <div className="all-content">
          <div className="logo-authblock">
            <div className="logowhite">
              <img src={logoWhite} alt="logo" />
              <div
                className="send-button"
                onClick={() => window.location.replace("/registration/")}
              >
                Зарегистрироваться
              </div>
            </div>

            <div className="authblock">
              {authState === false ? (
                <div>
                  <div className="authblock-tittle">Авторизоваться</div>
                  <div className="authblock_parth">
                    <div className="textarea-login">
                      <input
                        className="input-auth"
                        id="login"
                        type="email"
                        placeholder="E-mail"
                      ></input>
                    </div>

                    <div className="textarea-password">
                      <input
                        className="input-auth"
                        id="password"
                        type="password"
                        placeholder="Пароль"
                      ></input>
                    </div>
                    <div className="authblock-pass-view">
                      <label
                        onClick={() => {
                          $("#password-checkbox-view").is(":checked")
                            ? $("#password").attr("type", "text")
                            : $("#password").attr("type", "password");
                        }}
                      >
                        <input
                          id="password-checkbox-view"
                          className="password-checkbox"
                          type="checkbox"
                        ></input>
                        Показать
                      </label>
                      <div className="checkbox-text-save-me">
                        <div
                          className="recover_password"
                          onClick={() => setAuthState(true)}
                        >
                          Востановить
                        </div>
                      </div>
                    </div>

                    <div className="authblock-fail">
                      {failText ? failText : false}
                    </div>
                    <div>
                      <div>
                        <div className="block-captcha">
                          <Captcha
                            propssetResultKeyCaptcha={setResultKeyCaptcha}
                            propsresultKeyCaptcha={resultKeyCaptcha}
                            propssetCaptchaResult={setCaptchaResult}
                            rebootCaptcha={rebootCaptcha}
                            setRebootCaptcha={setRebootCaptcha}
                          />
                          {captchaResult ? (
                            <div className="captcharesult-text">
                              {captchaResult}
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                        <div className="input-error">{inputError}</div>

                        {inactiveButton === false ? (
                          <div
                            className="send-button"
                            onClick={() => {
                              var login = $("#login").val();
                              var password = $("#password").val();
                              if (
                                resultKeyCaptcha &&
                                resultKeyCaptcha === true
                              ) {
                                AuthKey(login, password);
                                setCaptchaResult(false);
                              } else {
                                setCaptchaResult("Неверные символы");
                              }
                              setRebootCaptcha(true);
                            }}
                          >
                            Войти
                          </div>
                        ) : (
                          <div className="send-button">
                            <Spinner animation="border" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {statusForgotPassword &&
                  statusForgotPassword.status &&
                  statusForgotPassword.status === "y" ? (
                    <div className="result_text_recover recover_success">
                      {statusForgotPassword.status_text}
                    </div>
                  ) : (
                    <div>
                      <div className="authblock-tittle">
                        Восстановление пароля
                      </div>
                      <div className="authblock_parth">
                        <div className="textarea-login">
                          <input
                            className="input-auth"
                            id="login"
                            type="email"
                            placeholder="E-mail"
                          ></input>
                        </div>
                        <div className="block-captcha">
                          <Captcha
                            propssetResultKeyCaptcha={setResultKeyCaptcha}
                            propsresultKeyCaptcha={resultKeyCaptcha}
                            propssetCaptchaResult={setCaptchaResult}
                            rebootCaptcha={rebootCaptcha}
                            setRebootCaptcha={setRebootCaptcha}
                          />
                          {captchaResult ? (
                            <div className="captcharesult-text">
                              {captchaResult}
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                        <div className="send_button_auth">
                          {statusForgotPassword &&
                          statusForgotPassword.status &&
                          statusForgotPassword.status === "n" ? (
                            <div className="result_text_recover recover_error ">
                              {statusForgotPassword.status_text}
                            </div>
                          ) : (
                            false
                          )}
                          <div
                            className="send-button"
                            onClick={() => {
                              var login = $("#login").val();
                              AuthKeyRecover(login);
                              setRebootCaptcha(true);
                            }}
                          >
                            Восстановить
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="send_button_auth">
                    <div
                      className="send-button"
                      onClick={() => setAuthState(false)}
                    >
                      Авторизоваться
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="text-slogan">
            <div>
              Aritin - единая цифровая платформа для управления и контроля за
              рабочим процессом
            </div>
            <div>Aritin © 2023-2024</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
