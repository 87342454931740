import { useEffect, useState } from "react";
import getData from "../getdata/GetData";
import AritinEditor from "components/general/AritinEditor";

function TicketsFastComments({
  id_ticket_fast,
  ticket_change_box,
  set_ticket_change_box,
}) {
  const [listTicketsFastComments, setlistTicketsFastComments] = useState([]);

  const [changeField, setChangeField] = useState(false);

  const getTicketsFastComments = async (
    action,
    id_item,
    textValue,
    type,
    files,
    id_file
  ) => {
    const request_parameters = { module: "sd", block: "fast_tickets_comments" };
    const request_attribute = {
      action: action,
      id_item: id_item,
      id_ticket_fast: id_ticket_fast,
      id_file: id_file,
    };

    const request_data = {
      value: textValue,
      edit_field: type,
    };

    let result = [];
    if (files && files.length > 0) {
      result = await getData(
        request_parameters,
        request_attribute,
        request_data,
        files
      );
    } else {
      result = await getData(
        request_parameters,
        request_attribute,
        request_data
      );
    }
    const arrItemsTicketsFastComments =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setlistTicketsFastComments(arrItemsTicketsFastComments);
  };
  useEffect(() => {
    getTicketsFastComments();
  }, []);

  function saveDescription(textValue, id_item, selectedFileValue) {
    getTicketsFastComments(
      "add_public_comment",
      "",
      textValue,
      "",
      selectedFileValue
    );
  }

  function editDescription(textValue, id_item, selectedFileValue) {
    getTicketsFastComments(
      "edit_public_comment",
      id_item,
      textValue,
      "text",
      selectedFileValue
    );
  }
  function saveAnswerPublicComment(textValue, id_item, selectedFileValue) {
    getTicketsFastComments(
      "answer_public_comment",
      id_item,
      textValue,
      "",
      selectedFileValue
    );
  }

  function deleteFile(id_item, fileId) {
    getTicketsFastComments("delete_file", id_item, "", "", "", fileId);
  }

  function deleteFileService(id_item, fileId) {
    getTicketsFastComments("delete_service_file", id_item, "", "", "", fileId);
  }

  function editDescriptionService(textValue, id_item, selectedFileValue) {
    getTicketsFastComments(
      "edit_service_comment",
      id_item,
      textValue,
      "text",
      selectedFileValue
    );
  }
  function saveDescriptionService(textValue, id_item, selectedFileValue) {
    getTicketsFastComments(
      "add_service_comment",
      "",
      textValue,
      "",
      selectedFileValue
    );
  }

  function saveAnswerSrevicComment(textValue, id_item) {
    getTicketsFastComments("answer_service_comment", id_item, textValue);
  }

  return (
    <>
      {listTicketsFastComments ? (
        <div className="ticketfastcomment pt-4 pb-4">
          <div className="container">
            <div className="row">
              {/* Left Block */}
              <div
                className={` ${
                  listTicketsFastComments.service_comments &&
                  listTicketsFastComments.service_comments === "forbidden"
                    ? "col-12"
                    : "col-7"
                }`}
              >
                <div className="border_background p-3">
                  <div className="a-text-4 pb-2">Комментарии</div>
                  <div>
                    {listTicketsFastComments.public_comments
                      ? Object.keys(
                          listTicketsFastComments.public_comments
                        ).map((fastcomentkey, index) => {
                          const fastcomment =
                            listTicketsFastComments.public_comments[
                              fastcomentkey
                            ];
                          if (fastcomment) {
                            return (
                              <div
                                key={index}
                                className="ticketfast-items-comment"
                              >
                                <div>
                                  <div className="comment-text-user-date-message ticket-box-text">
                                    <div className="comment-user-date">
                                      <div className="comment-user">
                                        {fastcomment.user}
                                      </div>
                                      <div className="comment-date">
                                        {fastcomment.date}
                                      </div>
                                    </div>
                                    {fastcomment.answer_to > 0 ? (
                                      <div className="quote_tabs_comment">
                                        <div className="quote_comment_user_block">
                                          <div className="quote_comment_user">
                                            {
                                              listTicketsFastComments
                                                .public_comments[
                                                fastcomment.answer_to
                                              ].user
                                            }
                                          </div>
                                        </div>
                                        {
                                          listTicketsFastComments
                                            .public_comments[
                                            fastcomment.answer_to
                                          ].text
                                        }
                                      </div>
                                    ) : (
                                      false
                                    )}
                                    <div className="comment-message">
                                      <div
                                        className={`comment-message-aritineditor ${
                                          fastcomment && fastcomment.text
                                            ? "comment-message-true"
                                            : "comment-message-false"
                                        } ${
                                          fastcomment && fastcomment.files
                                            ? "comment-files-true"
                                            : ""
                                        }`}
                                      >
                                        {(fastcomment && fastcomment.text) ||
                                        (fastcomment && fastcomment.files) ? (
                                          <AritinEditor
                                            setChangeField={setChangeField}
                                            changeField={changeField}
                                            textProps={fastcomment.text}
                                            idProps={fastcomment.id}
                                            saveTextProps={editDescription}
                                            setChangeBoxProps={
                                              set_ticket_change_box
                                            }
                                            propsFile={fastcomment.files}
                                            propsStartfile={"y"}
                                            deleteFileProps={deleteFile}
                                            propsAccess={fastcomment.access}
                                          />
                                        ) : (
                                          false
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {ticket_change_box &&
                                ticket_change_box ===
                                  "change_comment" + fastcomment.id ? (
                                  false
                                ) : (
                                  <div
                                    className={` comment-buttons-block ${
                                      listTicketsFastComments.service_comments &&
                                      listTicketsFastComments.service_comments ===
                                        "forbidden"
                                        ? "comment-buttons-block-forbidden"
                                        : ""
                                    } ${
                                      fastcomment.access &&
                                      fastcomment.access !== "change"
                                        ? "comment-button-read"
                                        : ""
                                    }`}
                                  >
                                    {fastcomment.access &&
                                    fastcomment.access === "change" ? (
                                      <div
                                        className="comment-button button-style"
                                        onClick={() =>
                                          getTicketsFastComments(
                                            "delete_public_comment",
                                            fastcomment.id
                                          )
                                        }
                                      >
                                        Удалить
                                      </div>
                                    ) : (
                                      false
                                    )}

                                    <div
                                      className="comment-button button-style"
                                      onClick={() =>
                                        set_ticket_change_box(
                                          "answer_public_comment" +
                                            fastcomment.id
                                        )
                                      }
                                    >
                                      Ответить
                                    </div>
                                  </div>
                                )}

                                {"answer_public_comment" + fastcomment.id ===
                                ticket_change_box ? (
                                  <div className="edit-comment answer-comment-rumba">
                                    <div className="input-ticket-box-text">
                                      <div className="ticket-box-text">
                                        <AritinEditor
                                          setChangeField={setChangeField}
                                          changeField={changeField}
                                          newCommentProps={"answer"}
                                          textProps={""}
                                          saveTextProps={
                                            saveAnswerPublicComment
                                          }
                                          idProps={fastcomment.id}
                                          setChangeBoxProps={
                                            set_ticket_change_box
                                          }
                                          propsStartfile={"y"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  false
                                )}
                              </div>
                            );
                          }
                        })
                      : false}
                  </div>
                  <div className="ticket-box-text">
                    <AritinEditor
                      setChangeField={setChangeField}
                      changeField={changeField}
                      textProps={""}
                      saveTextProps={saveDescription}
                      newCommentProps={"y"}
                      propsFile={[]}
                      propsStartfile={"y"}
                    />
                  </div>
                </div>
              </div>

              {/* Right Block */}
              {listTicketsFastComments &&
              listTicketsFastComments.service_comments &&
              listTicketsFastComments.service_comments === "forbidden" ? (
                false
              ) : (
                <div className="col-5">
                  <div className="border_background p-3">
                    <div className="a-text-4 pb-2">
                      Сервисные комментарии (скрыты от клиентов)
                    </div>
                    <div>
                      {listTicketsFastComments.service_comments
                        ? Object.keys(
                            listTicketsFastComments.service_comments
                          ).map((fastcomentkey, index) => {
                            const fastcomment =
                              listTicketsFastComments.service_comments[
                                fastcomentkey
                              ];
                            return (
                              <div
                                key={index}
                                className="ticketfascomment-items-comment"
                              >
                                <div>
                                  <div className="comment-text-user-date-message ticket-box-text">
                                    <div className="comment-user-date">
                                      <div className="comment-user">
                                        {fastcomment.user}
                                      </div>
                                      <div className="comment-date">
                                        {fastcomment.date}
                                      </div>
                                    </div>
                                    {fastcomment.answer_to > 0 ? (
                                      <div className="quote_tabs_comment">
                                        <div className="quote_comment_user_block">
                                          <div className="quote_comment_user">
                                            {
                                              listTicketsFastComments
                                                .service_comments[
                                                fastcomment.answer_to
                                              ].user
                                            }
                                          </div>
                                        </div>
                                        {
                                          listTicketsFastComments
                                            .service_comments[
                                            fastcomment.answer_to
                                          ].text
                                        }
                                      </div>
                                    ) : (
                                      false
                                    )}
                                    <div className="comment-message">
                                      <div className="comment-message-aritineditor service-comment-message-aritineditor">
                                        {(fastcomment && fastcomment.text) ||
                                        (fastcomment && fastcomment.files) ? (
                                          <AritinEditor
                                            setChangeField={setChangeField}
                                            changeField={changeField}
                                            textProps={fastcomment.text}
                                            idProps={fastcomment.id}
                                            saveTextProps={
                                              editDescriptionService
                                            }
                                            setChangeBoxProps={
                                              set_ticket_change_box
                                            }
                                            propsFile={fastcomment.files}
                                            propsStartfile={"y"}
                                            deleteFileProps={deleteFileService}
                                            propsAccess={fastcomment.access}
                                          />
                                        ) : (
                                          false
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {ticket_change_box &&
                                ticket_change_box ===
                                  "change_comment" + fastcomment.id ? (
                                  false
                                ) : (
                                  <div
                                    className={`comment-buttons-block ${
                                      fastcomment.access &&
                                      fastcomment.access !== "change"
                                        ? "comment-button-read"
                                        : ""
                                    }`}
                                  >
                                    {fastcomment.access &&
                                    fastcomment.access === "change" ? (
                                      <div
                                        className="comment-button button-style"
                                        onClick={() =>
                                          getTicketsFastComments(
                                            "delete_service_comment",
                                            fastcomment.id
                                          )
                                        }
                                      >
                                        Удалить
                                      </div>
                                    ) : (
                                      false
                                    )}

                                    <div
                                      className="comment-button button-style"
                                      onClick={() =>
                                        set_ticket_change_box(
                                          "answer_service_comment" +
                                            fastcomment.id
                                        )
                                      }
                                    >
                                      Ответить
                                    </div>
                                  </div>
                                )}

                                {"answer_service_comment" + fastcomment.id ===
                                ticket_change_box ? (
                                  <div className="edit-comment answer-comment-rumba">
                                    <div className="input-ticket-box-text">
                                      <div className="ticket-box-text">
                                        <AritinEditor
                                          setChangeField={setChangeField}
                                          changeField={changeField}
                                          newCommentProps={"answer"}
                                          textProps={""}
                                          saveTextProps={
                                            saveAnswerSrevicComment
                                          }
                                          idProps={fastcomment.id}
                                          setChangeBoxProps={
                                            set_ticket_change_box
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  false
                                )}
                              </div>
                            );
                          })
                        : false}
                      {}
                    </div>
                    <div className="ticket-box-text">
                      <AritinEditor
                        setChangeField={setChangeField}
                        changeField={changeField}
                        textProps={""}
                        saveTextProps={saveDescriptionService}
                        newCommentProps={"y"}
                        propsFile={[]}
                        propsStartfile={"y"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
}

export default TicketsFastComments;
