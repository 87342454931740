import { useEffect, useRef, useState } from "react";
import getData from "../servicdesk/getdata/GetData";
import { useParams } from "react-router-dom";
import FunctionChange from "components/general/FunctionChange";
import AritinEditor from "components/general/AritinEditor";

function Article({ setNameArticle }) {
  let { id } = useParams();
  const [knowArticle, setKnowArticle] = useState([]);
  const [changeFieldMain, setChangeFieldMain] = useState(false);

  useEffect(() => {
    getKnowledge("", id);
  }, [id]);

  const getKnowledge = async (action, id_item, edit_field, value) => {
    const request_parameters = {
      module: "guide",
      block: "article",
    };
    const request_attribute = {
      action,
      id_item,
    };

    const request_data = {
      edit_field,
      value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrListArticle =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setKnowArticle(arrListArticle);
  };

  function getValue(left_fig, value, id_item) {
    getKnowledge("edit_art", id_item, "name", value);
    setNameArticle(true);
  }
  function saveDescription(value, id_item) {
    getKnowledge("edit_art", id_item, "content", value);
  }

  return (
    <>
      <div className="article">
        {knowArticle ? (
          <>
            <div className="article-block">
              <div className="article-title-block">
                {knowArticle.name && knowArticle.name.value ? (
                  <FunctionChange
                    getValue={getValue}
                    propsValue={knowArticle.name.value}
                    propsIditem={
                      knowArticle.id && knowArticle.id.value
                        ? knowArticle.id.value
                        : false
                    }
                  />
                ) : (
                  false
                )}
              </div>
              <div className="article-info-block">
                {knowArticle.user && knowArticle.user.value ? (
                  <div className="article-info-items">
                    <div className="article-info-item">Автор</div>
                    <div className="article-info-value">
                      {knowArticle.user.value}
                    </div>
                  </div>
                ) : (
                  false
                )}

                {knowArticle.date && knowArticle.date.value ? (
                  <div className="article-info-items">
                    <div className="article-info-item">Дата создания</div>
                    <div className="article-info-value">
                      {knowArticle.date.value}
                    </div>
                  </div>
                ) : (
                  false
                )}

                <div
                  className="article-info-items"
                  onClick={() => {
                    if (window.confirm("Удалить?")) {
                      getKnowledge(
                        "del_art",
                        knowArticle.id && knowArticle.id.value
                          ? knowArticle.id.value
                          : false
                      );
                      setNameArticle(true);
                    }
                  }}
                >
                  <div className="article-info-item-del">
                    {knowArticle && knowArticle.name ? "Удалить статью" : false}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>Нет информации</div>
        )}
      </div>
      <div className="article-aritin-editor">
        {knowArticle && knowArticle.name ? (
          <AritinEditor
            textProps={
              knowArticle && knowArticle.content && knowArticle.content.value
                ? knowArticle.content.value
                : "Нет информации"
            }
            propsAccess={
              knowArticle && knowArticle.content && knowArticle.content.access
                ? knowArticle.content.access
                : "read"
            }
            saveTextProps={saveDescription}
            setOpenEditer={() => true}
            setChangeField={setChangeFieldMain}
            changeField={changeFieldMain}
            idProps={
              knowArticle.id && knowArticle.id.value
                ? knowArticle.id.value
                : false
            }
          />
        ) : (
          false
        )}
      </div>
    </>
  );
}

export default Article;
