import { useDrag } from "react-dnd";
import { MdDragIndicator } from "react-icons/md";
import FunctionChange from "components/general/FunctionChange";
import { FiTrash } from "react-icons/fi";
import { useRef } from "react";

function SettingStatusItem({
  nameStatusvalue,
  index2,
  getValueStatus,
  getNameStatus,
  nameGroupvalue,
}) {
  const ref = useRef(null);
  const [{ isDrag }, dragRef] = useDrag({
    type: "status",
    item: { id: nameStatusvalue.id },
    collect: (monitor) => ({
      isDrag: monitor.isDragging(),
    }),
  });

  return (
    !isDrag && (
      <div
        ref={
          nameStatusvalue.access && nameStatusvalue.access === "change"
            ? dragRef
            : null
        }
        className={`setting-status_group-item ${
          nameStatusvalue.access && nameStatusvalue.access === "change"
            ? ""
            : "service-stutus"
        }`}
      >
        {nameStatusvalue.access && nameStatusvalue.access === "change" ? (
          <MdDragIndicator />
        ) : (
          false
        )}

        <FunctionChange
          getValue={getValueStatus}
          propsValue={nameStatusvalue.value}
          fieldType={nameStatusvalue.field}
          propsState={nameStatusvalue.new}
          propsIditem={nameStatusvalue.id}
          access={nameStatusvalue.access}
        />

        {nameStatusvalue.access && nameStatusvalue.access === "change" ? (
          <div
            className="block_delete_fitrash_no_border"
            onClick={() => {
              if (window.confirm("Удалить?")) {
                getNameStatus(nameStatusvalue.id, "delete_status");
              }
            }}
          >
            <FiTrash />
          </div>
        ) : (
          false
        )}
      </div>
    )
  );
}

export default SettingStatusItem;
