import FunctionChange from "components/general/FunctionChange";
import { useState, useEffect } from "react";
import getData from "components/unit/servicdesk/getdata/GetData";
import { FiTrash } from "react-icons/fi";
import "../../../css/settings.css";
import SettingGroupStatus from "./SettingGroupStatus";

function SettingServiceDesk() {
  const [typeTicket, setTypeTicket] = useState([]);
  const [nameStatus, setNameStatus] = useState([]);

  const getTypeTicket = async (id_item, action, edit_field, value) => {
    const request_parameters = {
      module: "settings",
      block: "tickets_type",
    };
    const request_attribute = {
      id_item: id_item,
      action: action,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const settingTypeTicket =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setTypeTicket(settingTypeTicket);
  };

  const getNameStatus = async (id_item, action, edit_field, value) => {
    const request_parameters = {
      module: "settings",
      block: "statuses",
    };
    const request_attribute = {
      id_item: id_item,
      action: action,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const settingNameStatus =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setNameStatus(settingNameStatus);
  };

  useEffect(() => {
    getTypeTicket();
    getNameStatus();
  }, []);

  function getValueTypeTickets(fieldType, value, id_item) {
    getTypeTicket(id_item, "", fieldType, value);
  }

  function getValueStatus(fieldType, value, id_item) {
    getNameStatus(id_item, "change_status", fieldType, value);
  }

  function getValueGroup(fieldType, value, id_item) {
    getNameStatus(id_item, "change_group", fieldType, value);
  }
  const onDropHandler = (groupID, item) => {
    getNameStatus(groupID, "move_status", "", item);
  };

  return (
    <>
      <div className="setting_right_area">
        <div className="setting_right_area-title a-text-2">
          Настройка задач и заявок
        </div>

        <div className="setting_right_area-group">
          <div className="setting_right_area-group-title">
            Настройка статусов задач
          </div>
          <div className="setting_right_area_block">
            {nameStatus
              ? nameStatus.map((nameGroupvalue, index) =>
                  nameGroupvalue && nameGroupvalue.name ? (
                    <SettingGroupStatus
                      onDropHandler={onDropHandler}
                      key={index}
                      nameGroupvalue={nameGroupvalue}
                      getValueGroup={getValueGroup}
                      getNameStatus={getNameStatus}
                      getValueStatus={getValueStatus}
                      index={index}
                    />
                  ) : (
                    false
                  )
                )
              : false}
          </div>
          <div className="setting_right_area-button_area a-flex a-gap-20">
            <div className="btn_one" onClick={() => getNameStatus("", "add")}>
              Добавить статус
            </div>
            <div
              className="btn_one"
              onClick={() => getNameStatus("", "add_group")}
            >
              Добавить группу
            </div>
          </div>
        </div>

        <div className="setting_right_area-group">
          <div className="setting_right_area-group-title">Типы задач</div>

          <div className=" ">
            <div className="">
              {typeTicket
                ? typeTicket.map((typeTicketvalue, index) =>
                    typeTicketvalue ? (
                      <div
                        key={index}
                        className="settings-bottom-right-items a-flex"
                      >
                        <FunctionChange
                          getValue={getValueTypeTickets}
                          propsValue={typeTicketvalue.name.value}
                          fieldType={typeTicketvalue.name.field}
                          propsState={typeTicketvalue.new}
                          propsIditem={typeTicketvalue.id.value}
                        />

                        <div
                          className="block_delete_fitrash_no_border"
                          onClick={() =>
                            getTypeTicket(typeTicketvalue.id.value, "delete")
                          }
                        >
                          <FiTrash />
                        </div>
                      </div>
                    ) : (
                      false
                    )
                  )
                : false}
            </div>
            <div onClick={() => getTypeTicket("", "add")} className="btn_one">
              Добавить
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingServiceDesk;
