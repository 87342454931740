import { useState, useEffect } from "react";
import getData from "../getdata/GetData";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { arrConstCounterpartie } from "../const/ConstTicket";

function Counterparties() {
  const { id } = useParams();
  const [parties, setParties] = useState([]);

  useEffect(() => {
    listCounterparties();
  }, []);

  const listCounterparties = async (id_item, action) => {
    const request_parameters = {
      module: "settings",
      block: "projects",
      type: "get",
    };
    const request_attribute = {
      content: { value: "all" },
      id_item: id_item,
      action: action,
    };

    const result = await getData(request_parameters, request_attribute);

    const arrCounterparties =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setParties(arrCounterparties);
  };

  return (
    <>
      <div className="counterparties_all_content">
        <div className="container">
          <div className="title_h1__add_counterpartie">
            <div className="a-text-1 a-section-title">Список контрагентов</div>
            <div
              className="a-btn_block_add"
              onClick={() => listCounterparties("", "add")}
            >
              Добавить контрагента
            </div>
          </div>

          <div className="counterparties_table_block">
            <div className="counterparties_table__counterparties_title_table border_background">
              <div className="counterparties_title_table">
                <div>
                  <div></div>
                </div>
                <div>
                  {arrConstCounterpartie &&
                  arrConstCounterpartie.name &&
                  arrConstCounterpartie.name.title ? (
                    <div>{arrConstCounterpartie.name.title}</div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {arrConstCounterpartie &&
                  arrConstCounterpartie.actual_address &&
                  arrConstCounterpartie.actual_address.title ? (
                    <div>{arrConstCounterpartie.actual_address.title}</div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {arrConstCounterpartie &&
                  arrConstCounterpartie.phone &&
                  arrConstCounterpartie.phone.title ? (
                    <div>{arrConstCounterpartie.phone.title}</div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {arrConstCounterpartie &&
                  arrConstCounterpartie.website &&
                  arrConstCounterpartie.website.title ? (
                    <div>{arrConstCounterpartie.website.title}</div>
                  ) : (
                    false
                  )}
                </div>

                <div>
                  {arrConstCounterpartie &&
                  arrConstCounterpartie.project_manager &&
                  arrConstCounterpartie.project_manager.title ? (
                    <div>{arrConstCounterpartie.project_manager.title}</div>
                  ) : (
                    false
                  )}
                </div>
              </div>

              {parties
                ? parties.map((arrpartie, index) =>
                    arrpartie ? (
                      <div
                        className="counterparties_item counterparties_grid_template"
                        key={index}
                      >
                        <div className="counterparties_arrpartie_item-block">
                          <div className="counterparties_arrpartie_item counterpartie_logo">
                            <Link to={`/counterparties/${arrpartie.id.value}/`}>
                              {arrpartie.image && arrpartie.image.value ? (
                                <img src={arrpartie.image.value} />
                              ) : (
                                <div className="counterparties_no_logo">
                                  {arrpartie.name.value &&
                                  arrpartie.name.value !== "Новый контрагент"
                                    ? arrpartie.name.value[0] +
                                      arrpartie.name.value[1]
                                    : false}
                                </div>
                              )}
                            </Link>
                          </div>
                          <div className="counterparties_arrpartie_item counterpartie_name title_h5">
                            <Link to={`/counterparties/${arrpartie.id.value}/`}>
                              {arrpartie.name.value}
                            </Link>
                          </div>
                        </div>

                        <div className="counterparties_arrpartie_item">
                          <Link to={`/counterparties/${arrpartie.id.value}/`}>
                            {arrpartie.actual_address.value}
                          </Link>
                        </div>
                        <div className="counterparties_arrpartie_item">
                          <Link to={`/counterparties/${arrpartie.id.value}/`}>
                            {arrpartie.phone.value}
                          </Link>
                        </div>
                        <div className="counterparties_arrpartie_item">
                          <Link to={`/counterparties/${arrpartie.id.value}/`}>
                            {arrpartie.website.value}
                          </Link>
                        </div>

                        <div className="counterparties_arrpartie_item">
                          <Link to={`/counterparties/${arrpartie.id.value}/`}>
                            {arrpartie.project_manager.value}
                          </Link>
                        </div>
                        <div
                          className="counterparties_arrpartie_item block_delete_fitrash"
                          onClick={() => {
                            if (window.confirm("Удалить?")) {
                              listCounterparties(arrpartie.id.value, "delete");
                            }
                          }}
                        >
                          <FiTrash />
                        </div>
                      </div>
                    ) : (
                      false
                    )
                  )
                : false}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Counterparties;
