import { useState, useEffect } from "react";
import getData from "../getdata/GetData";

function ChangePasswordAuth({ id_item, type_password }) {
  const [passwordValue, setPasswordValue] = useState([]);

  const getUserPassword = async () => {
    const request_parameters = {
      module: "auth",
      block: "forgot_password_2",
    };
    const request_attribute = {
      id_item: id_item,
      action: "action_2",
      type: type_password,
    };

    const result = await getData(request_parameters, request_attribute);

    const arrUserPassword =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setPasswordValue(arrUserPassword);
  };

  return (
    <>
      {passwordValue.status === "y" ? (
        <div>{passwordValue.status_text}</div>
      ) : (
        <div
          onClick={() => getUserPassword()}
          className="change_password a-btn-block a-margin-auto"
        >
          {type_password === "props_new"
            ? "Пригласить сотрудника"
            : "Сменить пароль"}
        </div>
      )}
    </>
  );
}

export default ChangePasswordAuth;
