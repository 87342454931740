import { useEffect, useState } from "react";
import $ from "jquery";
import md5 from "md5";

function Captcha({
  propssetResultKeyCaptcha,
  propssetCaptchaResult,
  rebootCaptcha,
  setRebootCaptcha,
}) {
  const [randomNum, setRandomNum] = useState("");
  const [captchaKey, setCaptchaKey] = useState([
    {
      value: "8d66e410348e8472ff9aeed731505710",
      image: "/image_file/capcha/captchatwo.jpg",
    },
    {
      value: "107c516092f82e084f83d9f6bb779250",
      image: "/image_file/capcha/captchathree.jpg",
    },
    {
      value: "2723627df271deb8466a354de3c9d161",
      image: "/image_file/capcha/captchafour.jpg",
    },
    {
      value: "78be99af393330d9d7ff936ea9577d01",
      image: "/image_file/capcha/captchafive.jpg",
    },
    {
      value: "b5b4aa794613ee8c54629363bf4d700f",
      image: "/image_file/capcha/captchaone.jpg",
    },
  ]);

  function captchaStart() {
    propssetCaptchaResult(false);
    let inputValueCaptcha = $(".inputValueCaptcha").val();

    if (
      inputValueCaptcha &&
      captchaKey[randomNum].value === md5(inputValueCaptcha)
    ) {
      propssetResultKeyCaptcha(true);
    } else {
      console.log("her 4");
    }
  }

  function capthcaRandom() {
    let letRandomNum = Math.floor(Math.random() * 4 + 1);
    setRandomNum(letRandomNum);
    propssetCaptchaResult(false);
  }

  function capthcaRandomOnlyImage() {
    let letRandomNum = Math.floor(Math.random() * 4 + 1);
    setRandomNum(letRandomNum);
  }

  useEffect(() => {
    capthcaRandom();
  }, []);

  useEffect(() => {
    capthcaRandomOnlyImage();
    setRebootCaptcha(false);
  }, [rebootCaptcha]);

  return (
    <>
      <div className="captcha">
        <div className="captcha-block-area">
          <div className="captcha-block-img">
            {randomNum && captchaKey && captchaKey[randomNum] ? (
              <div>
                <img
                  src={
                    captchaKey[randomNum].image
                      ? captchaKey[randomNum].image
                      : "ошибка капчи"
                  }
                  alt="Капча"
                />
              </div>
            ) : (
              false
            )}
          </div>

          <input
            className="inputValueCaptcha"
            id="code"
            type="text"
            placeholder="Код с картинки"
            onChange={() => captchaStart()}
          ></input>
        </div>
        <div className="btn-update-captcha" onClick={() => capthcaRandom()}>
          Обновить картинку
        </div>
      </div>
    </>
  );
}

export default Captcha;
