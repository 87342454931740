export const arrTitleRoles = {
  task: {
    title: "Задачи",
    default: "Нет данных",
  },
  ticket: {
    title: "Заявки",
    default: "Нет данных",
  },
  dopservice: {
    title: "Общие",
    default: "Нет данных",
  },
};
