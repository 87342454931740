import React, { useState, useEffect } from "react";
import getData from "../getdata/GetData";
import $ from "jquery";
import { arrSelectors } from "../const/DataControlPanel";
import moment from "moment";
import { AiFillCheckSquare, AiOutlineCloseSquare } from "react-icons/ai";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";

function TicketControlPanel({
  idTicket,
  changeField,
  setChangeField,
  changeFieldRef,
  typeItem,
}) {
  const [ticketControlPanel, setTicketControlPanel] = useState([]);
  const [selectorControlPanel, setSelectorControlPanel] = useState([]);
  const [isOpenMobileFilter, setIsOpenMobileFilter] = useState(false);

  let expand = "xxl";
  if (window.screen.width <= 576) {
    expand = "mobile";
  }

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (idTicket > 0) {
      getTicketControlPanel();
    }
  }, [idTicket]);

  const handleOutsideClick = (event) => {
    if (
      $(event.target).hasClass("even-button-more-no-active") ||
      $(event.target).hasClass("even-button-more-active") ||
      $(event.target).hasClass("even-button-more") ||
      $(event.target).hasClass("even-button-more-btn") ||
      $(event.target).parent().hasClass("even-button-more-btn")
    ) {
      $(".even-button-more").toggleClass("active-even-button");
      $(".even-button-other-statuses").toggleClass(
        "even-button-other-statuses-active"
      );
    }

    if (
      !event.target.closest(".even-button-other-statuses") &&
      !event.target.closest(".even-button-more")
    ) {
      $(".even-button-other-statuses").removeClass(
        "even-button-other-statuses-active"
      );
      $(".even-button-more").removeClass("active-even-button");
    }

    if (
      (!event.target.closest(".control_panel_project") &&
        changeFieldRef.current === "control_panel_project") ||
      (!event.target.closest(".control_panel_priority") &&
        changeFieldRef.current === "control_panel_priority") ||
      (!event.target.closest(".control_panel_date_control") &&
        changeFieldRef.current === "control_panel_date_control") ||
      (!event.target.closest(".control_panel_users_executor") &&
        !event.target.closest(
          ".selector-value-block-control-panel-users_executor"
        ) &&
        changeFieldRef.current === "control_panel_users_executor") ||
      (!event.target.closest(".control_panel_department") &&
        changeFieldRef.current === "control_panel_department") ||
      (!event.target.closest(".control_panel_runtime") &&
        changeFieldRef.current === "control_panel_runtime") ||
      (!event.target.closest(".control_panel_common") &&
        changeFieldRef.current === "control_panel_common") ||
      (!event.target.closest(".control_panel_type") &&
        changeFieldRef.current === "control_panel_type") ||
      (!event.target.closest(".control_panel_users_co_executor") &&
        !event.target.closest(
          ".selector-value-block-control-panel-users_co_executor"
        ) &&
        changeFieldRef.current === "control_panel_users_co_executor") ||
      (!event.target.closest(".control_panel_users_observer") &&
        !event.target.closest(
          ".selector-value-block-control-panel-users_observer"
        ) &&
        changeFieldRef.current === "control_panel_users_observer")
    ) {
      setChangeField(null);
    }
  };

  async function getTicketControlPanel(action, edit_field, value) {
    const request_parameters = {
      module: "sd",
      block: "ticket_info",
    };
    const request_attribute = {
      id_ticket: idTicket,
      action: action,
      typeItem,
    };
    let request_data = {
      edit_field: edit_field,
      value: value,
    };
    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrTicketControlPanel = {
      fields:
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [],
      hot_button:
        result && result.request_data && result.request_data.hot_button
          ? result.request_data.hot_button
          : [],
      status:
        result && result.request_data && result.request_data.status
          ? result.request_data.status
          : [],
      id:
        result && result.request_data && result.request_data.id
          ? result.request_data.id
          : [],
    };

    if (arrTicketControlPanel && edit_field === "status" && value === "995") {
      window.location.replace("/task/");
    }

    if (
      arrTicketControlPanel &&
      edit_field === "status" &&
      arrTicketControlPanel.id &&
      arrTicketControlPanel.id > 0 &&
      value === "2"
    ) {
      window.location.replace("/task/" + arrTicketControlPanel.id);
    }

    $(".even-button-more").removeClass("active-even-button");
    $(".even-button-other-statuses").removeClass(
      "even-button-other-statuses-active"
    );
    setTicketControlPanel(arrTicketControlPanel);
    setChangeField(false);
  }

  function getListSelector(getTypeSelector) {
    const module = arrSelectors[getTypeSelector].module;
    const block = arrSelectors[getTypeSelector].block;
    const getItemsAsync = async () => {
      const request_parameters = { module: module, block: block, type: "get" };
      const request_attribute = { content: { value: "name" }, for: "tickets" };
      const result = await getData(request_parameters, request_attribute);

      const arrItemsSelector =
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [];

      setSelectorControlPanel(arrItemsSelector);
    };
    getItemsAsync();
  }

  function checkedElementInArray(arr, element) {
    let currentID = 0;

    if (arr && arr.length !== 0) {
      for (let i = 0; i < arr.length; i++) {
        currentID = parseInt(arr[i].id);
        if (currentID === element) {
          return true;
        }
      }
    }

    return false;
  }

  return (
    <>
      <div
        className={`btn-block-mobile ${
          isOpenMobileFilter === true ? "active" : ""
        }`}
      >
        {expand === "mobile" ? (
          <div
            className={`mobile-control-panel ${
              isOpenMobileFilter === true ? "active" : ""
            }`}
            onClick={() => setIsOpenMobileFilter(!isOpenMobileFilter)}
          >
            {isOpenMobileFilter === true ? (
              <div className="mobile-control-button">
                Закрыть панель управления
              </div>
            ) : (
              <div className="mobile-control-button">Панель управления</div>
            )}
          </div>
        ) : (
          false
        )}
      </div>

      <div
        className={`ticket_block_control_panel_all border_background ${
          isOpenMobileFilter === true ? "active" : ""
        }`}
      >
        {ticketControlPanel && ticketControlPanel.fields ? (
          <div className="content_ticket_block_control_panel">
            {typeItem && typeItem === "template" ? (
              false
            ) : (
              <div
                className={`status_hot status` + ticketControlPanel.status.id}
              >
                <div className="status_online">
                  {ticketControlPanel.status && ticketControlPanel.status.value
                    ? ticketControlPanel.status.value
                    : false}
                </div>
              </div>
            )}

            {ticketControlPanel &&
            ticketControlPanel.hot_button &&
            ticketControlPanel.hot_button.one &&
            ticketControlPanel.hot_button.one.value &&
            ticketControlPanel.hot_button.one.value !== "" ? (
              <div
                className={`hot-button-control-panel pb-4 ${
                  ticketControlPanel.hot_button.more &&
                  ticketControlPanel.hot_button.more.length > 0
                    ? ""
                    : "nogap"
                }`}
              >
                <div className="status-next-one">
                  {ticketControlPanel.hot_button.one.value !== "" ? (
                    <div
                      className="status-next-one-button"
                      onClick={() =>
                        getTicketControlPanel(
                          "edit",
                          "status",
                          ticketControlPanel.hot_button.one.id
                        )
                      }
                    >
                      {ticketControlPanel.hot_button.one.value}
                    </div>
                  ) : (
                    false
                  )}
                </div>

                {ticketControlPanel.hot_button.more &&
                ticketControlPanel.hot_button.more.length > 0 ? (
                  <div className="even-block-area">
                    <div className="even-block">
                      <div className="even-button even-button-more">
                        <span className="even-button-more-no-active">
                          <IoChevronDownOutline className="even-button-more-btn" />
                        </span>
                        <span className="even-button-more-active">
                          <IoChevronUpOutline className="even-button-more-btn" />
                        </span>
                      </div>
                      <div className="even-button-other-statuses">
                        {ticketControlPanel.hot_button.more.map(
                          ticketControlPanel
                            ? (hot_button_item, index) => (
                                <div
                                  key={index}
                                  className="control-panel-hot-button-more-value"
                                  onClick={() => {
                                    getTicketControlPanel(
                                      "edit",
                                      "status",
                                      hot_button_item.id
                                    );
                                  }}
                                >
                                  {hot_button_item && hot_button_item.value
                                    ? hot_button_item.value
                                    : false}
                                </div>
                              )
                            : false
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            ) : (
              false
            )}

            {Object.keys(ticketControlPanel.fields).map((item, index) => {
              const items = ticketControlPanel.fields[item]; // отделяем по ключам

              const selector = arrSelectors[item];

              return (
                <div key={index} className="control-panel-block">
                  {"control_panel_" + item === changeField &&
                  items.access === "change" ? (
                    <div
                      className={
                        "control-panel-title-value control_panel_" + item
                      }
                    >
                      {items.type === "date" ? (
                        <div className="selector-date-save-cancel">
                          <div
                            className="selector-date-save"
                            onClick={(e) => {
                              var inputVal = $(
                                "#dateInputTicketControlPanel"
                              ).val();
                              getTicketControlPanel("edit", item, inputVal);
                            }}
                          >
                            <AiFillCheckSquare />
                          </div>
                          <div
                            className="selector-date-cancel"
                            onClick={() => setChangeField([])}
                          >
                            <AiOutlineCloseSquare />
                          </div>
                        </div>
                      ) : (
                        false
                      )}

                      <div className="selector-tittle-control-panel">
                        {selector.title}
                      </div>
                      <div className="control_panel_items_block shadow">
                        {Array.isArray(items.value) ? (
                          <div className="control-panel-default-value-block">
                            {items.value.map((user_item, index) => (
                              <div
                                className="control_panel_default_value"
                                key={index}
                                onClick={() =>
                                  getTicketControlPanel(
                                    "delete",
                                    item,
                                    user_item.id
                                  )
                                }
                              >
                                {user_item.value}
                              </div>
                            ))}
                          </div>
                        ) : (
                          false
                        )}

                        {items.type === "selector" ? (
                          selectorControlPanel &&
                          selectorControlPanel.length > 0 ? (
                            selectorControlPanel.map((item2, index) =>
                              checkedElementInArray(items.value, item2.id) ? (
                                false
                              ) : (
                                <div
                                  key={index}
                                  className="control_panel_selector_value"
                                  onClick={() =>
                                    getTicketControlPanel(
                                      "edit",
                                      item,
                                      item2.id
                                    )
                                  }
                                >
                                  {item2.value}
                                </div>
                              )
                            )
                          ) : (
                            <div
                              className="control_panel_selector_value"
                              onClick={() => setChangeField(null)}
                            >
                              Данные отсутствуют
                            </div>
                          )
                        ) : (
                          false
                        )}

                        {items.type === "date" ? (
                          <div className="control-panel-selector-date">
                            <div className="date-input-ticket-control-panel">
                              <input
                                id="dateInputTicketControlPanel"
                                className=""
                                type="datetime-local"
                                defaultValue={`${moment(
                                  items.value,
                                  "YYYY.MM.DD HH:mm:ss"
                                ).format("YYYY-MM-DDTHH:mm")}`}
                              ></input>
                            </div>
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  ) : items.type === "date" ? (
                    <div
                      className="control-panel-title-value"
                      onClick={() => {
                        setChangeField("control_panel_" + item);
                      }}
                    >
                      <div className="selector-tittle-control-panel">
                        {selector.title}
                      </div>
                      <div
                        className={`selector-value-block-control-panel ${
                          items.value && items.value !== "0000-00-00 00:00:00"
                            ? "filled_in"
                            : false
                        }`}
                      >
                        {items.value === "0000-00-00 00:00:00"
                          ? selector.default
                          : moment(items.value, "YYYY.MM.DD HH:mm:ss").format(
                              "DD.MM.YYYY HH:mm"
                            )}
                      </div>
                    </div>
                  ) : items.type === "date_fix" ? (
                    <div className="control-panel-title-value">
                      <div>
                        <div className="selector-tittle-control-panel cursor_no_drop">
                          {selector.title}
                        </div>
                        <div className="selector-value-block-control-panel cursor_no_drop">
                          {items.value === "0000-00-00 00:00:00"
                            ? selector.default
                            : moment(items.value, "YYYY.MM.DD HH:mm:ss").format(
                                "DD.MM.YYYY HH:mm"
                              )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="control-panel-title-value ">
                      <div
                        className={`selector-tittle-control-panel ${
                          item === "users_initiator" ? "cursor_no_drop" : ""
                        }`}
                      >
                        {selector.title}
                      </div>
                      {Array.isArray(items.value) ? (
                        <div
                          className={`selector-value-block-control-panel ${
                            item === "users_initiator" ? "cursor_no_drop" : ""
                          }`}
                          onClick={() => {
                            setSelectorControlPanel([]);
                            setChangeField("control_panel_" + item);
                            getListSelector(item);
                          }}
                        >
                          {items.value.length === 0
                            ? selector.default
                            : items.value.map((user_item, index) => (
                                <div
                                  className={`selector-value-control-panel ${
                                    user_item.value ? "filled_in" : false
                                  }`}
                                  key={index}
                                >
                                  <div className="selector_item_item_value">
                                    <div
                                      className={
                                        "selector-value-block-control-panel-" +
                                        item
                                      }
                                    >
                                      {user_item.value}
                                    </div>
                                  </div>
                                </div>
                              ))}
                        </div>
                      ) : (
                        <div
                          className={`selector-value-block-control-panel ${
                            items.value ? "filled_in" : false
                          }`}
                          onClick={() => {
                            setSelectorControlPanel([]);
                            setChangeField("control_panel_" + item);
                            getListSelector(item);
                          }}
                        >
                          {items.value ? items.value : selector.default}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          false
        )}
      </div>
    </>
  );
}

export default TicketControlPanel;
