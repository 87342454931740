function NotPaid() {
  return (
    <>
      <div className="no_paid">
      <div className="p-4">
        <div className="a-text-1 pb-4 ">Отрицательный баланс</div>
        <div className="a-text-3 pb-2 color_red">
          Аккаунт компании заблокирован, для продолжения работы пополните баланс
        </div>
        <div>
          Если вы считаете, что это ошибка, пожалуйста, свяжитесь с
          администратором системы Aritin для уточнения информации о вашем
          статусе доступа или возможных ограничениях.
        </div>
        <div className="paid_href" >Перейти на {' '}
        <a href="/payment/">страницу оплаты</a>
        </div>
      </div>
      </div>















    </>
  );
}

export default NotPaid;
