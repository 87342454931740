import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import getData from "../getdata/GetData";
import $ from "jquery";
import { MdDeleteOutline, MdCheckBoxOutlineBlank } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { IoCheckboxSharp } from "react-icons/io5";

function CheckList({ typeItem }) {
  const { id } = useParams();
  const [ticketCheckList, setTicketCheckList] = useState([]);
  const [activeFieldCheckList, setActiveFieldCheckList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  /* DRAG */
  const [activeDragElemntValue, setActiveDragElemntValue] = useState([]);
  const [activeDragElemntLocal, setActiveDragElemntLocal] = useState([]);
  const [activeDragCheckList, setActiveDragCheckList] = useState([]);
  const [accessCheckList, setAccessCheckList] = useState("read");

  const handleDragStart = (e, index, index_parent) => {
    e.dataTransfer.setData("text/plain", index);
    setActiveDragElemntLocal(index);
    setActiveDragCheckList(index_parent);
    setActiveDragElemntValue(ticketCheckList[index_parent]["checks"][index]);
  };

  const handleDragOver = (e, index, index_parent) => {
    e.preventDefault();
    if (
      index_parent === activeDragCheckList &&
      activeDragElemntLocal !== index
    ) {
      let ticketCheckListVar = ticketCheckList;
      if (activeDragElemntLocal < index) {
        for (let i = activeDragElemntLocal; i < index; i++) {
          ticketCheckListVar[index_parent]["checks"][i] =
            ticketCheckListVar[index_parent]["checks"][i + 1];
        }
        ticketCheckListVar[index_parent]["checks"][index] =
          activeDragElemntValue;
      }
      if (activeDragElemntLocal > index) {
        for (let i = activeDragElemntLocal; i > index; i--) {
          ticketCheckListVar[index_parent]["checks"][i] =
            ticketCheckListVar[index_parent]["checks"][i - 1];
        }
        ticketCheckListVar[index_parent]["checks"][index] =
          activeDragElemntValue;
      }
      setTicketCheckList(ticketCheckListVar);
      setActiveDragElemntLocal(index);
    }
  };

  const handleDragEnd = (index_parent, id_checkList) => {
    setTicketCheckList((prevItems) => {
      const newItems = prevItems.slice();
      newItems[index_parent]["checks"][activeDragElemntLocal] =
        activeDragElemntValue;
      return newItems;
    });
    getTicketCheckList(
      "sort",
      "check_item",
      id_checkList,
      "",
      ticketCheckList[index_parent]["checks"]
    );
  };
  /* DRAG END */

  useEffect(() => {
    getTicketCheckList();
  }, []);

  async function getTicketCheckList(
    action,
    type,
    id_check_list,
    id_check_item,
    value
  ) {
    const request_parameters = {
      module: "sd",
      block: "ticket_checklist",
    };
    const request_attribute = {
      id_ticket: id,
      action: action,
      type: type,
      id_check_list: id_check_list,
      id_check_item: id_check_item,
      typeItem,
    };

    let request_data = {
      value: value,
    };
    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrTicketCheckList =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    const stringAccessCheckList =
      result && result.request_data && result.request_data.access
        ? result.request_data.access
        : "read";
    setTicketCheckList(arrTicketCheckList);
    setAccessCheckList(stringAccessCheckList);
  }

  return (
    <div className="check-list-area">
      {ticketCheckList.map((checkList, index_parent) => (
        <div key={index_parent} className="check_list">
          <div className="check_list_title_checks_block">
            <div className="check-list-title">
              <div className="check-list-title-name">
                {activeFieldCheckList === "editCheckList" + checkList.id ? (
                  <div className="textarea-save-cancel-textarea-checklist-block">
                    <div className="save-cancel-textarea-checklist-block">
                      <textarea id="textarea-checklist-list" rows="1">
                        {checkList.name.value}
                      </textarea>
                    </div>
                    <div className="save-cancel-textarea-checklist">
                      <div
                        className="save-textarea"
                        onClick={() => {
                          getTicketCheckList(
                            "edit",
                            "check_list",
                            checkList.id,
                            "",
                            $("#textarea-checklist-list").val()
                          );
                          setActiveFieldCheckList([]);
                        }}
                      >
                        Сохранить
                      </div>
                      <div
                        className="cancel-textarea"
                        onClick={() => {
                          setActiveFieldCheckList([]);
                          getTicketCheckList();
                        }}
                      >
                        Отменить
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="check_list_title_name_value"
                    onClick={() =>
                      checkList.name &&
                      checkList.name.access &&
                      checkList.name.access === "change"
                        ? setActiveFieldCheckList(
                            "editCheckList" + checkList.id
                          )
                        : false
                    }
                  >
                    {checkList.name.value}

                    {checkList.name &&
                    checkList.name.access &&
                    checkList.name.access === "change" ? (
                      <div className="icon_cheklist_title">
                        <CiEdit />
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                )}
              </div>

              <div className="check_list_title_state">
                (Выполнено {checkList.state.completed} из {checkList.state.all})
              </div>
              {checkList.name &&
              checkList.name.access &&
              checkList.name.access === "change" ? (
                <div
                  className="check_list_title_delete"
                  onClick={() => {
                    if (window.confirm("Удалить?")) {
                      getTicketCheckList(
                        "del",
                        "check_list",
                        checkList.id,
                        "",
                        ""
                      );
                    }
                  }}
                >
                  Удалить чек лист
                </div>
              ) : (
                false
              )}
            </div>

            <div className="check-list-checks-block">
              {checkList.checks.map((checkItem, index) =>
                checkItem ? (
                  <div
                    key={index}
                    className="check-list-check"
                    draggable
                    onDragStart={(e) => handleDragStart(e, index, index_parent)}
                    onDragOver={(e) => handleDragOver(e, index, index_parent)}
                    onDragEnd={(e) => handleDragEnd(index_parent, checkList.id)}
                  >
                    <div
                      className="check-list-check-completed"
                      onClick={() =>
                        getTicketCheckList(
                          "complete",
                          "check_item",
                          checkList.id,
                          index + 1,
                          ""
                        )
                      }
                    >
                      {checkItem.completed === "n" ? (
                        <div className="check_box_punkt">
                          <MdCheckBoxOutlineBlank />
                        </div>
                      ) : (
                        <div className="check_box_punkt check_box_completed">
                          <IoCheckboxSharp />
                        </div>
                      )}
                    </div>

                    {activeFieldCheckList ===
                    "editCheckItem" + checkList.id + (index + 1) ? (
                      <div className="check_list_check_items_value">
                        <div className="check_list_check_value">
                          <textarea id="textarea-checklist-check">
                            {checkItem.value}
                          </textarea>
                        </div>
                        <div className="textarea-checklist-check-button">
                          <div
                            className="save-textarea button_cursor"
                            onClick={() => {
                              getTicketCheckList(
                                "edit",
                                "check_item",
                                checkList.id,
                                index + 1,
                                $("#textarea-checklist-check").val()
                              );
                              setActiveFieldCheckList([]);
                            }}
                          >
                            Сохранить
                          </div>
                          <div
                            className="cancel-textarea button_cursor"
                            onClick={() => {
                              setActiveFieldCheckList([]);
                              getTicketCheckList();
                            }}
                          >
                            Отменить
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`check_list_check_value ${
                          checkItem.completed !== "n" ? "text-decoration" : ""
                        }`}
                        onClick={() =>
                          checkList.name &&
                          checkList.name.access &&
                          checkList.name.access === "change"
                            ? setActiveFieldCheckList(
                                "editCheckItem" + checkList.id + (index + 1)
                              )
                            : false
                        }
                      >
                        {checkItem.value}
                      </div>
                    )}

                    {checkList.name &&
                    checkList.name.access &&
                    checkList.name.access === "change" ? (
                      <div
                        className="check_list_punkt_delete"
                        onClick={() => {
                          getTicketCheckList(
                            "del",
                            "check_item",
                            checkList.id,
                            index + 1,
                            ""
                          );
                        }}
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                ) : (
                  false
                )
              )}

              {checkList.new === "Y" ||
              activeFieldCheckList === "addCheckItem" + checkList.id ? (
                <div className="check-list-check">
                  <div className="check-list-check-completed"></div>
                  <div className="check_list_check_value">
                    <textarea
                      className=""
                      id="textarea-checklist-check"
                      rows="1"
                    ></textarea>
                    <div className="textarea-checklist-check-button">
                      <div
                        className="save-textarea"
                        onClick={() => {
                          var checkItemText = $(
                            "#textarea-checklist-check"
                          ).val();
                          getTicketCheckList(
                            "add",
                            "check_item",
                            checkList.id,
                            "",
                            checkItemText
                          );
                          setActiveFieldCheckList(
                            "addCheckItem" + checkList.id
                          );
                          $("#textarea-checklist-check").val("");
                        }}
                      >
                        Добавить пункт
                      </div>

                      <div
                        className="save-textarea"
                        onClick={() => {
                          var checkItemText = $(
                            "#textarea-checklist-check"
                          ).val();
                          getTicketCheckList(
                            "add",
                            "check_item",
                            checkList.id,
                            "",
                            checkItemText
                          );
                          setActiveFieldCheckList([]);
                        }}
                      >
                        Сохранить
                      </div>
                      <div
                        className="cancel-textarea"
                        onClick={() => {
                          setActiveFieldCheckList([]);
                          getTicketCheckList();
                        }}
                      >
                        Отменить
                      </div>
                    </div>
                  </div>
                </div>
              ) : checkList.name &&
                checkList.name.access &&
                checkList.name.access === "change" ? (
                <div
                  className="add-new-item"
                  onClick={() =>
                    setActiveFieldCheckList("addCheckItem" + checkList.id)
                  }
                >
                  Добавить новый пункт
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      ))}

      {accessCheckList && accessCheckList === "change" ? (
        <div
          className="add-check-list"
          onClick={() => getTicketCheckList("add", "check_list", "", "", "")}
        >
          Добавить чек лист
        </div>
      ) : (
        false
      )}
    </div>
  );
}

export default CheckList;
