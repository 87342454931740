export const arrSelectors = {
  search: {
    title: "Поиск",
    default: "Поиск задачи",
    module: "search",
    block: "ticket",
    type: "inputsearch",
  },
  status: {
    filter: "Y",
    title: "Статус",
    default: "Все",
    module: "settings",
    block: "statuses",
    type: "selector",
  },
  priority: {
    title: "Приоритет",
    default: "Все",
    module: "settings",
    block: "priorities",
    type: "selector",
  },
  project: {
    title: "Клиент",
    default: "Все",
    module: "settings",
    block: "projects",
    type: "selector",
  },
  users_executor: {
    title: "Исполнитель",
    default: "Все",
    module: "settings",
    block: "users",
    type: "selector",
  },
};
