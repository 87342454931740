import { useState, useEffect } from "react";
import SettingServiceDesk from "./SettingServiceDesk";
import SettingCompanyRequisites from "./SettingCompanyRequisites";
import SettingCompany from "./SettingCompany";
import getData from "components/unit/servicdesk/getdata/GetData";
import PlugAccess from "../../general/PlugAccess";
import "../../../css/settings.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function MainSettings() {
  const [responseCode, setResponseCode] = useState(null);
  const [currentSetting, setCurrentSetting] = useState("main");

  const arrMenuSetting = [
    { id: "main", name: "Основные настройки" },
    { id: "requisites", name: "Реквизиты компании" },
    { id: "sd", name: "Настройка задач и заявок" },
    { id: "crm", name: "Настройка ЦРМ" },
  ];

  const getCompanyInformation = async (id_item, action, edit_field, value) => {
    const request_parameters = {
      module: "settings",
      block: "main_settings",
    };
    const request_attribute = {
      id_item: id_item,
      action: action,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data
    );

    const settingResponseCode =
      result && result.request_attribute && result.request_attribute.status_code
        ? result.request_attribute.status_code
        : null;

    setResponseCode(settingResponseCode);
  };
  useEffect(() => {
    getCompanyInformation();
  }, []);

  if (responseCode && responseCode === "403") {
    return <PlugAccess />;
  } else if (responseCode && responseCode === "200") {
    return (
      <>
        <div className="setting-block">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="a-text-1 a-section-title">Настройки</div>
              </div>
              <div className="col-3 mb-4">
                <div className="border_background p-4 setting_menu_block">
                  <div className="setting_menu">
                    {arrMenuSetting.map((itemSetting, indexsetting) => (
                      <div
                        key={indexsetting}
                        className={`setting_menu_item ${
                          currentSetting === itemSetting.id ? "active" : null
                        }`}
                        onClick={() => setCurrentSetting(itemSetting.id)}
                      >
                        {itemSetting.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-9 mb-4">
                <DndProvider backend={HTML5Backend}>
                  <div className="border_background p-4 ">
                    {currentSetting === "main" ? (
                      <SettingCompany />
                    ) : currentSetting === "sd" ? (
                      <SettingServiceDesk />
                    ) : currentSetting === "requisites" ? (
                      <SettingCompanyRequisites />
                    ) : (
                      false
                    )}
                  </div>
                </DndProvider>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MainSettings;
