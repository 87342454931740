export const symbolEmojis = [
  { symbol: "😊", label: "Смайлик" },
  { symbol: "😂", label: "Смайлик2" },
  { symbol: "😄", label: "Смайлик с улыбкой" },
  { symbol: "😆", label: "Смайлик с широкой улыбкой" },
  { symbol: "😃", label: "Смайлик с улыбкой и слезами" },
  { symbol: "😍", label: "Смайлик влюбленный" },
  { symbol: "😘", label: "Смайлик с поцелуем" },
  { symbol: "😗", label: "Смайлик с сердечком" },
  { symbol: "😙", label: "Смайлик с улыбающимся лицом" },
  { symbol: "😚", label: "Смайлик с закрывшимися глазами" },
  { symbol: "😛", label: "Смайлик с высунутым языком" },
  { symbol: "😝", label: "Смайлик с высунутым языком и улыбкой" },
  { symbol: "😞", label: "Смайлик с грустным лицом" },
  { symbol: "😟", label: "Смайлик с обеспокоенным лицом" },
  { symbol: "😠", label: "Смайлик с сердитым лицом" },
  { symbol: "😡", label: "Смайлик с очень сердитым лицом" },
  { symbol: "🙏", label: "Смайлик с руками в молитве" },
  { symbol: "🤩", label: "Смайлик с разъяренным лицом" },
  { symbol: "🤣", label: "Смайлик с широкой улыбкой и слезами" },
  { symbol: "🙄", label: "Смайлик с закатанными глазами" },
  { symbol: "😒", label: "Смайлик с недовольным лицом" },
  { symbol: "😓", label: "Смайлик с плакальщиком" },
  { symbol: "😭", label: "Смайлик с громким плачем" },
  { symbol: "🤔", label: "Смайлик с задумчивым лицом" },
  { symbol: "🤷‍♂️", label: "Смайлик с пожиманием плечами" },
  { symbol: "🚀", label: "Корабль" },
  { symbol: "👽", label: "Пришелец" },
  { symbol: "🤖", label: "Робот" },
  { symbol: "📱", label: "Мобильный телефон" },
  { symbol: "💻", label: "Компьютер" },
  { symbol: "🎉", label: "Праздничные хлопушки" },
  { symbol: "🎂", label: "Торт" },
  { symbol: "🍰", label: "Кекс" },
  { symbol: "🧁", label: "Пирожное" },
  { symbol: "🥨", label: "Попкорн" },
  { symbol: "🍿", label: "Кино" },
  { symbol: "🎬", label: "Киностудия" },
  { symbol: "📺", label: "Телевизор" },
  { symbol: "📻", label: "Радио" },
  { symbol: "📚", label: "Книга" },
  { symbol: "📖", label: "Открытая книга" },
  { symbol: "📰", label: "Газета" },
  { symbol: "👀", label: "Глаза" },
  { symbol: "👂", label: "Указательный палец" },
  { symbol: "🤝", label: "Рукопожатие" },
  { symbol: "🙌", label: "Аплодисменты" },
  { symbol: "👏", label: "Хлопки в ладоши" },
];
