import { useRef } from "react";
import { useDrag } from "react-dnd";
import { Link } from "react-router-dom";
import { MdDragIndicator } from "react-icons/md";
function ArticleTitleCat({ articlerticlesmap, index2 }) {
  const ref = useRef(null);
  const [{ isDrag }, dragRef] = useDrag({
    type: "knowledge",
    item: { id: articlerticlesmap.id.value },
    collect: (monitor) => ({
      isDrag: monitor.isDragging(),
    }),
  });

  return (
    !isDrag && (
      <>
        <div key={index2} ref={dragRef} className="article-item-title">
          <div>
            <MdDragIndicator />
          </div>
          <div>
            <Link to={`/knowledge/${articlerticlesmap.id.value}/`}>
              {articlerticlesmap.name.value}
            </Link>
          </div>
        </div>
      </>
    )
  );
}

export default ArticleTitleCat;
