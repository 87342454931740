import React, { useState, useEffect, useRef } from "react";
import ContentEditable from "react-contenteditable";
import $ from "jquery";
import "../../css/aritineditor.css";
import { CiEdit } from "react-icons/ci";
import { IoClose, IoDocumentOutline } from "react-icons/io5";
import GallerySlider from "./GallerySlider";
import { FiTrash } from "react-icons/fi";

function AritinEditor({
  textProps,
  saveTextProps, //Функция отправки аргументов
  newCommentProps,
  idProps,
  setChangeBoxProps,
  propsFile,
  propsStartfile,
  deleteFileProps,
  setChangeField,
  changeField,
  propsAccess,
  propsVarOne, // Общая переменная
  setOpenEditer, //Открытие - Закрытие
}) {
  const [textValue, setTextValue] = useState(textProps);
  const [selectedFile, setSelectedFile] = useState([]);
  const [randomIdValueOpen, setRandomIdValueOpen] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImageIndex, setGalleryImageIndex] = useState("");
  const [randomIdValue, setRandomIdValue] = useState(
    Math.floor(Math.random() * (99999 - 10000) + 10000)
  );

  const handleChangeText = (evt) => {
    setTextValue(evt.target.value);
  };

  useEffect(() => {
    if (changeField && changeField !== randomIdValue) {
      setRandomIdValueOpen(0);
      setSelectedFile([]);
    }
  }, [changeField]);

  useEffect(() => {
    if (randomIdValueOpen && randomIdValueOpen > 0) {
      setChangeField(randomIdValue);
      setSelectedFile([]);
      document.addEventListener("click", handleRandomIdValueOpen);
      return () => {
        document.removeEventListener("click", handleRandomIdValueOpen);
      };
    }
  }, [randomIdValueOpen]);

  const handleRandomIdValueOpen = (event) => {
    if (newCommentProps !== "y") {
      if (
        $(event.target).closest(".aritin-editor-box") ||
        $(event.target).hasClass("a-btn-block-save1")
      ) {
      } else {
        setRandomIdValueOpen(0);
        setTextValue(textProps);
        setSelectedFile([]);
      }
    }
  };

  useEffect(() => {
    setTextValue(textProps);
  }, [textProps]);

  useEffect(() => {
    // newCommentProps === "y" ||
    if (newCommentProps === "answer") {
      setRandomIdValueOpen(randomIdValue);
    }
  }, []);

  if (!setChangeBoxProps) {
    const setChangeBoxProps1 = (oneArgument) => {
      return true;
    };

    setChangeBoxProps = setChangeBoxProps1;
  }
  function addOnChangeFile(changeFile) {
    if (changeFile.target.files && changeFile.target.files.length > 0) {
      let arrFilesAll = Array.from(changeFile.target.files);
      arrFilesAll.forEach((element) => {
        setSelectedFile((prevSelectedFile) => [...prevSelectedFile, element]);
      });
    }
  }

  const deleteFileFromState = (index) => {
    const newArray = [...selectedFile]; // Создаем копию массива
    newArray.splice(index, 1); // Удаляем элемент по индексу
    setSelectedFile(newArray); // Обновляем состояние с новым массивом
  };

  return (
    <>
      <div
        className={`aritineditor-area ${"value_id_" + randomIdValue} ${
          changeField &&
          changeField === randomIdValue &&
          randomIdValueOpen &&
          randomIdValueOpen > 0 &&
          randomIdValueOpen === randomIdValue
            ? "active"
            : ""
        }
          `}
      >
        <pre>
          <ContentEditable
            id={"aritinEditor_" + randomIdValue}
            className={
              "functionChange-article aritineditor-value value_id_" +
              randomIdValue
            }
            html={textValue}
            disabled={
              changeField &&
              changeField === randomIdValue &&
              randomIdValueOpen &&
              randomIdValueOpen > 0 &&
              randomIdValueOpen === randomIdValue
                ? false
                : true
            }
            tagName="article"
            onChange={handleChangeText}
          />
        </pre>

        {changeField &&
        changeField === randomIdValue &&
        randomIdValueOpen &&
        randomIdValueOpen > 0 &&
        randomIdValueOpen === randomIdValue ? (
          <div className={"buuton-bllock-ticket value_id_" + randomIdValue}>
            {propsStartfile && propsStartfile === "y" ? (
              <div className="">
                <form method="post" encType="multipart/form-data">
                  <label htmlFor="btnFromAddFileCommentredactor">
                    Прикрепить файл
                  </label>
                  <input
                    className="class_display_none"
                    id="btnFromAddFileCommentredactor"
                    type="file"
                    onChange={addOnChangeFile}
                    multiple
                  />
                </form>
              </div>
            ) : (
              false
            )}
            {newCommentProps !== "y" ? (
              <>
                <div
                  className="a-btn-block-cansel"
                  onClick={() => {
                    setTextValue(textProps);
                    setChangeBoxProps(false);
                    setRandomIdValueOpen(0);
                    setSelectedFile(false);
                    setOpenEditer(false);
                  }}
                >
                  Отменить
                </div>

                <div
                  className="a-btn-block-save"
                  onClick={() => {
                    saveTextProps(
                      textValue,
                      idProps,
                      selectedFile,
                      propsVarOne
                    );
                    setChangeBoxProps(false);
                    setRandomIdValueOpen(0);
                    setSelectedFile(false);
                    setOpenEditer(false);
                  }}
                >
                  Сохранить
                </div>
              </>
            ) : (textValue && textValue.length > 0) ||
              (selectedFile && selectedFile.length > 0) ? (
              <div
                className="a-btn-block-save"
                onClick={() => {
                  saveTextProps(textValue, "", selectedFile);
                  setTextValue("");
                  setSelectedFile(false);
                  setRandomIdValueOpen(0);
                }}
              >
                Отправить
              </div>
            ) : (
              <div className="a-btn-block-save">Отправить</div>
            )}
          </div>
        ) : (
          <>
            {newCommentProps === "y" ? (
              <div className="btn-block-aritineditor">
                <div
                  onClick={() => {
                    setRandomIdValueOpen(randomIdValue);
                  }}
                  className="a-btn-block-save"
                >
                  Добавить комментарий
                </div>
              </div>
            ) : (
              <>
                {propsAccess && propsAccess === "change" ? (
                  <div
                    className={
                      "icon-ticket-description value_id_" + randomIdValue
                    }
                    onClick={() => {
                      setRandomIdValueOpen(randomIdValue);
                      setChangeBoxProps("change_comment" + idProps);
                      setOpenEditer(true);
                    }}
                  >
                    <CiEdit />
                    Редактировать
                  </div>
                ) : (
                  false
                )}
              </>
            )}
          </>
        )}
      </div>

      {/* вывод файлов */}
      {propsStartfile && propsStartfile === "y" ? (
        <>
          <div>
            <GallerySlider
              galleryImages={galleryImages}
              galleryImageIndex={galleryImageIndex}
              setGalleryImageIndex={setGalleryImageIndex}
            />
          </div>

          {propsFile ? (
            <div className="comment-arr-map-files-items-block arr_map_files_image_block">
              {propsFile.image ? (
                <div className="ticket_main_files-image_items">
                  {propsFile.image.map((fileImage, indexImage) => (
                    <div
                      key={indexImage}
                      className="ticket_main_files-image_item"
                    >
                      <a>
                        <img
                          src={fileImage.url}
                          onClick={() => {
                            setGalleryImages(propsFile.image);
                            setGalleryImageIndex(indexImage);
                          }}
                        />
                      </a>
                      <div
                        className="ticket_main_files-image_del"
                        onClick={() => {
                          if (window.confirm("Удалить?")) {
                            deleteFileProps(idProps, fileImage.id);
                          }
                        }}
                      >
                        <IoClose />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                false
              )}

              {propsFile.other ? (
                <div className="ticket_main_files-other_items">
                  {propsFile.other.map((fileOther, indexOther) => (
                    <div
                      key={indexOther}
                      className="ticket_main_files-other_item"
                    >
                      <a href={fileOther.url} target="_blank">
                        <div className="ticket_main_files-other_name">
                          {fileOther.name}

                          <IoDocumentOutline />
                          {fileOther.size}
                        </div>
                      </a>
                      <IoClose
                        onClick={() => {
                          if (window.confirm("Удалить?")) {
                            deleteFileProps(idProps, fileOther.id);
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}

          {/* Завершение кода вывода файлов */}

          {selectedFile &&
          selectedFile.length > 0 &&
          propsStartfile &&
          propsStartfile === "y" ? (
            <div className="addfile-block">
              <div className="arr-map-block-img">
                {selectedFile.map((selectedfilemap, index) =>
                  selectedfilemap ? (
                    <div key={index} className="arr-map-item-img-svg">
                      <div className="arr-map-item-img">
                        <img
                          src={URL.createObjectURL(selectedfilemap)}
                          alt={index}
                        ></img>
                        <div
                          className=""
                          onClick={() => {
                            if (window.confirm("Удалить?")) {
                              deleteFileFromState(index);
                            }
                          }}
                        >
                          <FiTrash />
                        </div>
                      </div>
                    </div>
                  ) : (
                    false
                  )
                )}
              </div>
            </div>
          ) : (
            false
          )}
        </>
      ) : (
        false
      )}
    </>
  );
}
export default AritinEditor;
