function Unauthorized() {
  return (
    <>
      <div className="p-4">
        <div className="a-text-1 pb-4">Возникала ошибка сервера</div>
        <div className="a-text-3 pb-2">
          Доступ к запрашиваемому ресурсу запрешен. Требуются данные для
          аутентификации.
        </div>
        <div>
          К сожалению, во время вашего посещения нашего сайта произошла
          непредвиденная ошибка. Это может быть вызвано различными причинами,
          включая временные технические неполадки, проблемы с серверами или
          другие непредвиденные обстоятельства.
        </div>
        <div className="pt-2">
          Попробуйте обновить страницу через некоторое время. Возможно, проблема
          будет решена автоматически. Если проблема повторяется, пожалуйста,
          свяжитесь с администратором системы Aritin для уточнения информации.
        </div>
      </div>
    </>
  );
}

export default Unauthorized;
