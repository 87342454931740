import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import GetData from "components/unit/servicdesk/getdata/GetData";
import { useEffect, useState } from "react";
import avatar from "../../image/png/avatar.png";
import { CiLogout } from "react-icons/ci";
import moment from "moment";

function AppHeader({ propsHeader }) {
  const [time, setTime] = useState(propsHeader.time);
  const [needChangeTime, setNeedChangeTime] = useState(null);

  const logOutExit = async () => {
    const request_parameters = {
      module: "auth",
      block: "log_out",
    };
    const result = await GetData(request_parameters);

    const logOutExitResult =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    window.location.replace("/");
  };

  const timeUpdate = async () => {
    const request_parameters = {
      module: "auth",
      block: "time",
    };
    const resultTimeUpdate = await GetData(request_parameters);
    const arrTimeUpdate =
      resultTimeUpdate &&
      resultTimeUpdate.request_data &&
      resultTimeUpdate.request_data.items
        ? resultTimeUpdate.request_data.items
        : [];

    if (arrTimeUpdate && arrTimeUpdate.time) {
      setTime(arrTimeUpdate.time);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      timeUpdate();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      startChageTime();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    changeTime();
  }, [needChangeTime]);

  function startChageTime() {
    let randomNumber = Math.floor(Math.random() * 10) + 1;
    setNeedChangeTime(randomNumber);
  }

  function changeTime() {
    let arrTime = time.split(":");
    arrTime[2]++;
    if (arrTime[2] === 60) {
      arrTime[1]++;
      arrTime[2] = 0;
    }
    if (arrTime[1] === 60) {
      arrTime[0]++;
      arrTime[1] = 0;
    }
    if (arrTime[0] === 24) {
      arrTime[0] = 0;
    }

    setTime(arrTime[0] + ":" + arrTime[1] + ":" + arrTime[2]);
  }

  // let expand = "xxl";
  // if (window.screen.width <= 576) {
  //   expand = "mobile";
  // }

  return (
    <div className="App">
      <header className="App-header">
        <div className="row header_contetnt">
          <div className="col-4 col-sm-3 logo_block">
            <div className="logo_link_img">
              <Link to={`/`}>
                {propsHeader ? (
                  propsHeader.company_image ? (
                    <img src={propsHeader.company_image} />
                  ) : (
                    <div className="logo-name-text">
                      {propsHeader.company_name}
                    </div>
                  )
                ) : (
                  false
                )}
              </Link>
            </div>
          </div>
          <div className="col-none col-sm-4"></div>
          <div className="col-7 col-sm-5">
            <div className="row user_block">
              <div className="col-3 date_block">
                <div>
                  {time ? (
                    <div className="date_block_time">
                      <span>{`${moment(time, "HH:mm:ss").format("HH")}`}</span>
                      <span className="miganie">:</span>
                      <span>{`${moment(time, "HH:mm:ss").format("mm")}`}</span>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div className="date_block_date">
                  {propsHeader && propsHeader.date
                    ? `${moment(propsHeader.date, "YYYY.MM.DD").format(
                        "DD.MM.YYYY"
                      )}`
                    : false}
                </div>
              </div>
              <div className="col-6 user_name_avatar">
                {propsHeader && propsHeader.user_image ? (
                  <img src={propsHeader.user_image} />
                ) : (
                  <img src={avatar} alt="logo" />
                )}

                <div className="login_name">
                  {propsHeader && propsHeader.user_name
                    ? propsHeader.user_name
                    : false}
                </div>
              </div>
              <div className="col-4 col-sm-3 logout">
                <div>
                  <CiLogout />
                </div>
                <div
                  className="exit-text cp"
                  onClick={() => {
                    logOutExit();
                  }}
                >
                  Выйти
                </div>
              </div>
              {/* {expand === "mobile" ? (
                <div className="col-4 col-sm-3 mobile">
                  <div className="mobile-burger"></div>
                </div>
              ) : (
                false
              )} */}
              {/* {expand === "mobile" ? (
                <div
                  className="col-3 mobile-filter"
                  onClick={() => setIsOpenMobileFilter(true)}
                >
                  <div className="mobile-filter-panel">
                    <CiFilter />
                  </div>
                </div>
              ) : (
                false
              )} */}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default AppHeader;
