import FunctionChange from "components/general/FunctionChange";
import { FiTrash } from "react-icons/fi";
import SettingStatusItem from "./SettingStatusItem";
import { useDrop } from "react-dnd";
function SettingGroupStatus({
  nameGroupvalue,
  getValueGroup,
  getNameStatus,
  getValueStatus,
  index,
  onDropHandler,
}) {
  const [canDrop, dropRef] = useDrop({
    accept: "status",
    drop(item) {
      if (nameGroupvalue.access === "change") {
        onDropHandler(nameGroupvalue.id, item.id);
      }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <>
      <div
        ref={
          nameGroupvalue.access && nameGroupvalue.access === "change"
            ? dropRef
            : null
        }
        className={`setting-status_group ${
          nameGroupvalue.access && nameGroupvalue.access === "change"
            ? canDrop.canDrop === true
              ? "drop-highlighting"
              : ""
            : false
        } `}
      >
        <div
          className={`setting-status_group-title ${
            nameGroupvalue.access && nameGroupvalue.access === "change"
              ? ""
              : "service-group"
          }`}
        >
          <FunctionChange
            getValue={getValueGroup}
            propsValue={nameGroupvalue.name}
            fieldType={nameGroupvalue.field}
            propsIditem={nameGroupvalue.id}
            access={nameGroupvalue.access}
          />
          {nameGroupvalue.access && nameGroupvalue.access === "change" ? (
            <div
              className="block_delete_fitrash_no_border"
              onClick={() => getNameStatus(nameGroupvalue.id, "delete_group")}
            >
              <FiTrash />
            </div>
          ) : (
            false
          )}
        </div>

        <div className="setting-status_group-items">
          {nameGroupvalue.value
            ? nameGroupvalue.value.map((nameStatusvalue, index2) => (
                <SettingStatusItem
                  key={index2}
                  nameStatusvalue={nameStatusvalue}
                  index2={index2}
                  getValueStatus={getValueStatus}
                  getNameStatus={getNameStatus}
                  nameGroupvalue={nameGroupvalue}
                />
              ))
            : false}
        </div>
      </div>
    </>
  );
}

export default SettingGroupStatus;
