import React, { useState, useEffect } from "react";
import "../../../../css/tableticketsfast.css";
import getData from "../getdata/GetData";
import { FaRegEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  Subject,
  ID,
  UsersInitiator,
  UsersExecutor,
  Priority,
  StatusTask,
  Project,
} from "../const/ConstTicket";

function TableTicketsFast() {
  const [listTicketsFast, setListTableTicketsFast] = useState([]);

  const getTableTicketsFast = async (action) => {
    const request_parameters = {
      module: "sd",
      block: "fast_tickets_list",
    };

    const result = await getData(request_parameters);

    const arrItemsTableTicketsFast =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setListTableTicketsFast(arrItemsTableTicketsFast);
  };

  useEffect(() => {
    getTableTicketsFast();
  }, []);

  return (
    <>
      {listTicketsFast ? (
        <div className="tableticketsfast border_background">
          <div className="container">
            <div className="tableticketsfast-grid-template a-fw-600">
              <div className="tableticketsfast-item-title"> </div>
              <div className="tableticketsfast-item-title">{ID} </div>
              <div className="tableticketsfast-item-title">{Subject}</div>
              <div className="tableticketsfast-item-title">{UsersExecutor}</div>
              <div className="tableticketsfast-item-title">
                {UsersInitiator}
              </div>
              <div className="tableticketsfast-item-title">{Priority} </div>
              <div className="tableticketsfast-item-title">{Project} </div>
              <div className="tableticketsfast-item-title">{StatusTask}</div>
            </div>
            <div className="tableticketsfast-items">
              {listTicketsFast.map((listticketsfastvalue, index) =>
                listticketsfastvalue ? (
                  <div
                    key={index}
                    className="tableticketsfast-grid-template tableticketsfast-item"
                  >
                    <div>
                      {listticketsfastvalue.menu
                        ? listticketsfastvalue.menu.value
                        : false}
                    </div>
                    <div>
                      {listticketsfastvalue.id
                        ? listticketsfastvalue.id.value
                        : false}
                    </div>

                    <div>
                      <div>
                        <div className="a-flex a-gap-10">
                          {/* <div className="quick-view">
                            <FaRegEye />
                          </div> */}
                          <div>
                            {listticketsfastvalue.subject ? (
                              <Link
                                to={`/ticket/${listticketsfastvalue.id.value}/`}
                              >
                                {listticketsfastvalue.subject.value}
                              </Link>
                            ) : (
                              false
                            )}
                          </div>
                        </div>

                        <div className="quick-view-description-fast">
                          {listticketsfastvalue.description
                            ? listticketsfastvalue.description.value
                            : false}
                        </div>
                      </div>
                    </div>

                    <div className="tableticketsfast-users-executor">
                      {listticketsfastvalue.users_executor
                        ? listticketsfastvalue.users_executor.map(
                            (usersexecutor, index) =>
                              usersexecutor ? (
                                <div
                                  key={index}
                                  className="tableticketsfast-users-executor-item"
                                >
                                  {usersexecutor.value}
                                </div>
                              ) : (
                                false
                              )
                          )
                        : false}
                    </div>

                    <div>
                      {listticketsfastvalue.users_initiator
                        ? listticketsfastvalue.users_initiator[0].value
                        : false}
                    </div>

                    <div>
                      {listticketsfastvalue.priority
                        ? listticketsfastvalue.priority.value
                        : false}
                    </div>
                    <div>
                      {listticketsfastvalue.project
                        ? listticketsfastvalue.project.value
                        : false}
                    </div>
                    <div
                      className={
                        "status_color_" + listticketsfastvalue.status.id
                      }
                    >
                      <div className="status_color">
                        {listticketsfastvalue.status
                          ? listticketsfastvalue.status.value
                          : false}
                      </div>
                    </div>
                  </div>
                ) : (
                  false
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
}

export default TableTicketsFast;
