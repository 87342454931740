import { useState } from "react";
import Article from "./Article";
import KnowledgeCategories from "./KnowledgeCategories";
import "./knowledge.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function Knowledge() {
  const [nameArticle, setNameArticle] = useState(false);

  return (
    <>
      <div className="knowledge">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="a-text-1 a-section-title"> База знаний</div>
            </div>
            <DndProvider backend={HTML5Backend}>
              <div className="col-3">
                <div className="border_background p-3 knowledge-categories-area">
                  <KnowledgeCategories
                    setNameArticle={setNameArticle}
                    nameArticle={nameArticle}
                  />
                </div>
              </div>
              <div className="col-9">
                <div className="border_background p-4">
                  <Article setNameArticle={setNameArticle} />
                </div>
              </div>
            </DndProvider>
          </div>
        </div>
      </div>
    </>
  );
}

export default Knowledge;
