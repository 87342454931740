import FunctionChange from "components/general/FunctionChange";
import { useState, useEffect } from "react";
import getData from "components/unit/servicdesk/getdata/GetData";
import { arrSettingCompany } from "../servicdesk/const/ConstTicket";
import "../../../css/settings.css";
import { IoCameraReverseOutline } from "react-icons/io5";

function SettingCompany() {
  const [companyInformation, setCompanyInformation] = useState([]);

  const getCompanyInformation = async (
    id_item,
    action,
    edit_field,
    value,
    file
  ) => {
    const request_parameters = {
      module: "settings",
      block: "main_settings",
    };
    const request_attribute = {
      id_item: id_item,
      action: action,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data,
      file
    );

    const settingCompanyInformation =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setCompanyInformation(settingCompanyInformation);
  };

  useEffect(() => {
    getCompanyInformation();
  }, []);

  useEffect(() => {
    if (
      companyInformation &&
      companyInformation.main &&
      companyInformation.main.url &&
      companyInformation.main.url.value
    ) {
      let current_url = window.location.hostname;

      let new_url = companyInformation.main.url.value + ".aritin.ru";

      const currentUrl = window.location.href;
      const containsLocation = currentUrl.includes("localhost");

      if (new_url && new_url !== current_url && !containsLocation) {
        window.location.replace("https://" + new_url + "/settings/");
      }
    }
  }, [companyInformation]);

  function getValueGetCompanyInformation(fieldType, value, id_item) {
    getCompanyInformation(id_item, "", fieldType, value);
  }

  const getFileForSend = async (event) => {
    getCompanyInformation("", "", "image", "", event.target.files);
  };

  return (
    <>
      <div className="setting_right_area">
        <div className="setting_right_area-title a-text-2">
          Основные настройки
        </div>

        <div className="setting_right_area-group">
          <div className="setting_right_area-group-title">Логотип</div>
          <div className="settings-top-block-logo a-relative">
            {companyInformation.main &&
            companyInformation.main.image &&
            companyInformation.main.image.value ? (
              <img src={companyInformation.main.image.value}></img>
            ) : (
              "Добавить логотип"
            )}

            <form>
              <label
                htmlFor="btnFromAddFileSettingCompany"
                className="settings-top-block_image_label"
              >
                <IoCameraReverseOutline />
              </label>
              <input
                className="class_display_none"
                id="btnFromAddFileSettingCompany"
                type="file"
                onChange={getFileForSend}
              />
            </form>
          </div>
        </div>

        <div className="setting_right_area-group">
          <div className="setting_right_area-group-title">
            Название компании и адрес личного кабинета
          </div>
          <div className="setting_right_area-item">
            <div className="setting_right_area-item-name">
              Название компании
            </div>
            <FunctionChange
              getValue={getValueGetCompanyInformation}
              propsValue={
                companyInformation.main &&
                companyInformation.main.name &&
                companyInformation.main.name.value
                  ? companyInformation.main.name.value
                  : ""
              }
              fieldType="name"
            />
          </div>
          <div className="setting_right_area-item">
            <div className="setting_right_area-item-name">
              Адрес личного кабинета
            </div>
            {companyInformation.main &&
            companyInformation.main.url &&
            companyInformation.main.url.value ? (
              <FunctionChange
                getValue={getValueGetCompanyInformation}
                propsSubstitute="url_lk"
                propsState={
                  companyInformation.main &&
                  companyInformation.main.url &&
                  companyInformation.main.url.change
                    ? companyInformation.main.url.change
                    : false
                }
                propsFail={
                  companyInformation.main &&
                  companyInformation.main.url &&
                  companyInformation.main.url.fail
                    ? companyInformation.main.url.fail
                    : false
                }
                propsValue={
                  companyInformation.main &&
                  companyInformation.main.url &&
                  companyInformation.main.url.value
                    ? companyInformation.main.url.value
                    : ""
                }
                fieldType="url"
              />
            ) : (
              false
            )}
          </div>
        </div>

        <div className="setting_right_area-group">
          <div className="setting_right_area-group-title">
            Контактная информация
          </div>
          {companyInformation && companyInformation.info
            ? companyInformation.info.map((companyInformationvalue, index) =>
                companyInformationvalue ? (
                  <div key={index} className="setting_right_area-item">
                    <div className="setting_right_area-item-name">
                      {arrSettingCompany[companyInformationvalue.field].title}
                    </div>
                    <FunctionChange
                      getValue={getValueGetCompanyInformation}
                      propsValue={
                        companyInformationvalue.value
                          ? companyInformationvalue.value
                          : ""
                      }
                      fieldType={companyInformationvalue.field}
                    />
                  </div>
                ) : (
                  false
                )
              )
            : false}
        </div>
      </div>
    </>
  );
}

export default SettingCompany;
