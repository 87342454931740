export const Subject = "Тема";
export const ID = "ID";
export const Priority = "Приоритет";
export const DateControl = "Дата контроля";
export const StatusTask = "Статус";
export const Project = "Клиент";
export const UsersCoExecutor = "Соисполнитель";
export const UsersExecutor = "Исполнитель";
export const UsersInitiator = "Инициатор";
export const UsersObserver = "Наблюдатель";
export const More = "Еще";

export const arrConstCounterpartie = {
  name: {
    title: "Название контрагента",
    default: "Не заполнено",
  },
  website: {
    title: "Сайт",
    default: "Не заполнено",
  },

  type_counterpartie: {
    title: "Тип контрагента",
    default: "Не заполнено",
  },
  legal_address: {
    title: "Юридический адрес",
    default: "Не заполнено",
  },
  actual_address: {
    title: "Фактический адрес",
    default: "Не заполнено",
  },
  legal_entity: {
    title: "Юридическое лицо",
    default: "Не заполнено",
  },
  inn: {
    title: "ИНН",
    default: "Не заполнено",
  },
  ogrn: {
    title: "ОГРН",
    default: "Не заполнено",
  },
  kpp: {
    title: "КПП",
    default: "Не заполнено",
  },
  payment_account: {
    title: "Расчетный счет",
    default: "Не заполнено",
  },

  correspondent_account: {
    title: "Корреспондентский счёт",
    default: "Не заполнено",
  },
  bank: {
    title: "Банк",
    default: "Не заполнено",
  },
  edo: {
    title: "ЭДО",
    default: "Не заполнено",
  },
  phone: {
    title: "Телефон организации",
    default: "Не заполнено",
  },
  project_manager: {
    title: "Менеджер проекта",
    default: "Не заполнено",
  },
};

export const arrConstBranch = {
  name: {
    title: "Название филиала",
    default: "Не заполнено",
  },

  adress: {
    title: "Адрес филиала",
    default: "Не заполнено",
  },

  branch_type: {
    title: "Тип филиала",
    default: "Не заполнено",
  },

  mail: {
    title: "Почта",
    default: "Не заполнено",
  },

  phone: {
    title: "Телефон",
    default: "Не заполнено",
  },

  time: {
    title: "График работы",
    default: "Не заполнено",
  },
};

export const arrListСounterpartieContacts = {
  name: {
    title: "ФИО",
    default: "Не заполнено",
  },

  mail: {
    title: "Почта",
    default: "Не заполнено",
  },

  phone: {
    title: "Телефон",
    default: "Не заполнено",
  },

  post: {
    title: "Должность",
    default: "Не заполнено",
  },

  telegram: {
    title: "Telegram",
    default: "Не заполнено",
  },

  whatsapp: {
    title: "WhatsApp",
    default: "Не заполнено",
  },

  branch: {
    title: "Филиал",
    default: "Не заполнено",
  },
  avatar: {
    title: "Аватарка",
    default: "Не заполнено",
  },
  position: {
    title: "Должность",
    default: "Не заполнено",
  },
  online: {
    title: "В сети",
    default: "Не заполнено",
  },
};

export const ConstListUsers = {
  name: {
    title: "ФИО",
    default: "Не заполнено",
  },

  mail: {
    title: "Почта",
    default: "Не заполнено",
  },

  phone: {
    title: "Телефон",
    default: "Не заполнено",
  },

  post: {
    title: "Должность",
    default: "Не заполнено",
  },

  telegram: {
    title: "Telegram",
    default: "Не заполнено",
  },

  whatsapp: {
    title: "WhatsApp",
    default: "Не заполнено",
  },

  branch: {
    title: "Филиал",
    default: "Не заполнено",
  },

  department: {
    title: "Отдел",
    default: "Не заполнено",
  },
  position: {
    title: "Должность",
    default: "Не заполнено",
  },
  birthday: {
    title: "Дата рождения",
    default: "Не заполнено",
  },
  telegram: {
    title: "Telegram",
    default: "Не заполнено",
  },
  whatsapp: {
    title: "WhatsApp",
    default: "Не заполнено",
  },
};

export const arrSettingCompany = {
  phone: {
    title: "Телефон",
    default: "Не заполнено",
  },

  mail: {
    title: "Почта",
    default: "Не заполнено",
  },

  phone2: {
    title: "Дополнительный телефон",
    default: "Не заполнено",
  },

  adres: {
    title: "Адрес организации",
    default: "Не заполнено",
  },

  legal_entity: {
    title: "Название юридического лица",
    default: "Не заполнено",
  },
  legal_address: {
    title: "Юридический адрес",
    default: "Не заполнено",
  },
  inn: {
    title: "ИНН организации",
    default: "Не заполнено",
  },
  ogrn: {
    title: "ОГРН",
    default: "Не заполнено",
  },
  kpp: {
    title: "КПП",
    default: "Не заполнено",
  },
  pay_account: {
    title: "Расчетный счет",
    default: "Не заполнено",
  },
  correspondent_account: {
    title: "Корреспондентский счет банка",
    default: "Не заполнено",
  },
  bank_name: {
    title: "Название банка",
    default: "Не заполнено",
  },
  bank_inn: {
    title: "ИНН банка",
    default: "Не заполнено",
  },
  bank_adress: {
    title: "Адрес банка",
    default: "Не заполнено",
  },
  bank_bik: {
    title: "БИК банка",
    default: "Не заполнено",
  },
};

export const telegramBotConst = {
  telegram: {
    on: "Подключен",
    off: "Подключить",
    no_on: "Не подключен",
  },
};

export const UserProfileConst = {
  device: {
    title: "Устройство",
    default: "Не заполнено",
  },
  date: {
    title: "Дата и время",
    default: "Не заполнено",
  },
  ip: {
    title: "IP входа",
    default: "Не заполнено",
  },
};
