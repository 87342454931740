import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  IoDuplicateOutline,
  IoDocumentTextOutline,
  IoConstructOutline,
  IoWalletOutline,
  IoAlbumsOutline,
  IoFolderOpenOutline,
  IoPeopleOutline,
  IoGitMergeOutline,
  IoBookOutline,
  IoInformation,
} from "react-icons/io5";
import logo from "../../image/img/logo.png";

function Menu({
  client,
  module,
  propsMobileMenu,
  propsSetMobileMenu,
  propsExpand,
}) {
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if ($(event.target).hasClass("sub_section_text_title")) {
      propsSetMobileMenu(false);
    }
  };

  const sections =
    client && client === "inside"
      ? // если inside
        propsExpand !== "mobile"
        ? // если не mobile
          [
            {
              title: "",
              isOpen: true,

              subSections: [
                module && module.task && module.task === "y"
                  ? {
                      link: "/task/new/",
                      text: "Создать задачу",
                      icon: <IoDuplicateOutline />,
                      id: "new_tickets",
                    }
                  : false,
                module && module.ticket && module.ticket === "y"
                  ? {
                      link: "/ticket/new/",
                      text: "Создать заявку",
                      icon: <IoDuplicateOutline />,
                      id: "new_ticketsfast",
                    }
                  : false,
              ],
            },

            {
              title: "Задачи и заявки",
              isOpen: true,

              subSections: [
                module && module.ticket && module.ticket === "y"
                  ? {
                      link: "/ticket/",
                      text: "Заявки",
                      icon: <IoDocumentTextOutline />,
                    }
                  : false,
                module && module.task && module.task === "y"
                  ? {
                      link: "/task/",
                      text: "Задачи",
                      icon: <IoDocumentTextOutline />,
                    }
                  : false,
                module && module.task && module.task === "y"
                  ? {
                      link: "/template_task/",
                      text: "Шаблоны",
                      icon: <IoDocumentTextOutline />,
                    }
                  : false,
              ],
            },

            {
              title: "Контрагенты",
              isOpen: true,

              subSections: [
                {
                  link: "/counterparties/",
                  text: "Контрагенты",
                  icon: <IoFolderOpenOutline />,
                },
              ],
            },

            {
              title: "Компания",
              isOpen: true,

              subSections: [
                {
                  link: "/structure/",
                  text: "Структура",
                  icon: <IoGitMergeOutline />,
                },
                {
                  link: "/staff/",
                  text: "Сотрудники",
                  icon: <IoPeopleOutline />,
                },
                {
                  link: "/roles/",
                  text: "Права и роли",
                  icon: <IoAlbumsOutline />,
                },
              ],
            },
            {
              title: "Информация",
              isOpen: true,

              subSections: [
                {
                  link: "/knowledge/",
                  text: "База знаний",
                  icon: <IoInformation />,
                },
              ],
            },

            {
              title: "Параметры",
              isOpen: true,

              subSections: [
                {
                  link: "/payment/",
                  text: "Оплата",
                  icon: <IoWalletOutline />,
                },
                {
                  link: "/settings/",
                  text: "Настройки",
                  icon: <IoConstructOutline />,
                },
              ],
            },

            {
              title: "Поддержка",
              isOpen: true,

              subSections: [
                {
                  link: "/contact_support/",
                  text: "Поддержка",
                  icon: <IoBookOutline />,
                },
              ],
            },
          ]
        : [
            // если mobile
            {
              title: "",
              isOpen: true,

              subSections: [
                module && module.task && module.task === "y"
                  ? {
                      link: "/task/new/",
                      text: "Создать задачу",
                      icon: <IoDuplicateOutline />,
                      id: "new_tickets",
                    }
                  : false,
                module && module.ticket && module.ticket === "y"
                  ? {
                      link: "/ticket/new/",
                      text: "Создать заявку",
                      icon: <IoDuplicateOutline />,
                      id: "new_ticketsfast",
                    }
                  : false,
              ],
            },

            {
              title: "Задачи и заявки",
              isOpen: true,

              subSections: [
                module && module.ticket && module.ticket === "y"
                  ? {
                      link: "/ticket/",
                      text: "Заявки",
                      icon: <IoDocumentTextOutline />,
                    }
                  : false,
                module && module.task && module.task === "y"
                  ? {
                      link: "/task/",
                      text: "Задачи",
                      icon: <IoDocumentTextOutline />,
                    }
                  : false,
                module && module.task && module.task === "y"
                  ? {
                      link: "/template_task/",
                      text: "Шаблоны",
                      icon: <IoDocumentTextOutline />,
                    }
                  : false,
              ],
            },

            {
              title: "Поддержка",
              isOpen: true,

              subSections: [
                module && module.task && module.task === "y"
                  ? {
                      link: "/contact_support/",
                      text: "Поддержка",
                      icon: <IoBookOutline />,
                    }
                  : false,
              ],
            },
          ]
      : [
          // если не inside

          {
            title: "",
            isOpen: true,

            subSections: [
              {
                link: "/ticket/new/",
                text: "Создать заявку",
                icon: <IoDuplicateOutline />,
                id: "new_ticketsfast",
              },
            ],
          },

          {
            title: "Задачи и заявки",
            isOpen: true,

            subSections: [
              {
                link: "/ticket/",
                text: "Заявки",
                icon: <IoDocumentTextOutline />,
              },
            ],
          },
        ];

  return (
    <>
      <div
        className={`sidebar_menu border_right_background ${
          propsMobileMenu === true ? "active" : ""
        } `}
      >
        <div className="sidebar_menu_items">
          {sections.map((section, index) => (
            <div key={index} className="menu_section">
              {client && client === "inside" ? (
                <div
                  className={`section_title__section_menu ${
                    section.title === "" ? "none" : ""
                  } `}
                >
                  <div className="section_icon_tittle_text">
                    <div className="section_text_tittle">{section.title}</div>
                  </div>
                </div>
              ) : (
                false
              )}

              {section.isOpen && section.subSections !== null ? (
                <div className="sub_sections">
                  {section.subSections !== null
                    ? section.subSections.map((subSection, subIndex) =>
                        subSection && subSection.text ? (
                          <div
                            key={`subLinkBlok-${subIndex}`}
                            className="sub_sections_item"
                          >
                            <Link
                              to={subSection.link}
                              key={`subLink-${subIndex}`}
                            >
                              <div
                                className={
                                  "sub_section_icon__sub_section_text_title subSection_" +
                                  subSection.id
                                }
                              >
                                <div className="sub_section_icon">
                                  {subSection.icon}
                                </div>
                                <div className="sub_section_text_title">
                                  {subSection.text}
                                </div>
                              </div>
                            </Link>
                          </div>
                        ) : (
                          false
                        )
                      )
                    : false}
                </div>
              ) : (
                false
              )}
            </div>
          ))}

          <div className="app_logo_block">
            <div className="app_logo">
              <img src={logo} alt="logo" />
              <div className="copyright">
                <div className="copyright_text">&copy; 2024 Aritin.ru</div>
                <div className="made_in_rf"> Сделано в РФ</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
